import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import MyInput from "components/form/MyInput"
import { UrlBuilder } from "helpers/UrlBuilder"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApi, selectApi } from "store/reducers/apiSlice"

function JobPostStep4({ setFieldValue, values, formOptions, touched, errors }) {
  const [districtId, setDistrictId] = useState(
    values.address.district_id || null
  )
  const [divisionId, setDivisionId] = useState(
    values.address.divisionId || null
  )
  const dispatch = useDispatch()

  const { subDistricts } = useSelector(selectApi)

  useEffect(() => {
    if (districtId !== null) {
      dispatch(
        callApi({
          operationId: UrlBuilder.coreServiceApi(
            `core/location/districts/${districtId}/sub_districts`
          ),
          output: "subDistricts",
        })
      )
    }
  }, [districtId, dispatch])

  const divisionOptions = useMemo(
    () => [
      // { label: "Select Division", value: "default" },
      ...(formOptions?.districts?.map((division) => ({
        label: division.name,
        value: division.id,
      })) || []),
    ],
    [formOptions?.districts]
  )

  const districtOptions = useMemo(
    () => [
      // { label: "Select District", value: "default" },
      ...(formOptions?.districts
        ?.find((division) => division.id === values?.address?.divisionId)
        ?.districts?.map((district) => ({
          label: district.name,
          value: district.id,
        })) || []),
    ],
    [formOptions?.districts, values?.address?.divisionId]
  )

  return (
    <Box>
      <Grid container spacing={2}>
        <MyInput label="Address" name="address.address" />
        <MyInput
          label="Division"
          type="select"
          name="address.divisionId"
          labelKey="label"
          keyKey="value"
          valueKey="value"
          value={values?.address?.divisionId} // Make sure divisionId is correctly set
          onChange={(e) => {
            const selectedDivisionId = e.target.value
            setFieldValue("address.divisionId", selectedDivisionId)
            setDivisionId(selectedDivisionId)
            setFieldValue("address.district_id", "") // Reset district
            setDistrictId(null) // Reset district state
            setFieldValue("address.sub_district_id", "") // Reset sub-district
          }}
          options={divisionOptions}
        />

        {/* <MyInput
          label="Division"
          type="select"
          name="address.divisionId"
          labelKey="label"
          keyKey="value"
          valueKey="value"
          value={values?.address?.divisionId}
          onChange={(e) => {
            const selectedDivisionId = e.target.value
            setFieldValue("address.divisionId", selectedDivisionId)
            setDivisionId(selectedDivisionId)
            setFieldValue("address.district_id", "") // Reset district
            setDistrictId(null) // Reset district state
            setFieldValue("address.sub_district_id", "") // Reset sub-district
          }}
          options={divisionOptions}
        /> */}

        <MyInput
          label="District"
          name="address.district_id"
          type="select"
          labelKey="label"
          keyKey="value"
          valueKey="value"
          value={values?.address?.district_id} // Make sure district_id is correctly set
          options={districtOptions}
          onChange={(e) => {
            const selectedDistrictId = e.target.value
            setDistrictId(selectedDistrictId)
            setFieldValue("address.district_id", selectedDistrictId)
            setFieldValue("address.sub_district_id", "") // Reset sub-district
          }}
        />
        <MyInput
          type="select"
          label="Thana/Upazilla"
          name="address.sub_district_id"
          value={values?.address?.sub_district_id} // Ensure sub_district_id is correctly set
          options={subDistricts?.data || []}
          onChange={(e) => {
            const selectedSubDistrictId = e.target.value
            setFieldValue("address.sub_district_id", selectedSubDistrictId)
          }}
        />
      </Grid>
    </Box>
  )
}

export default JobPostStep4
