import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import i18n from "Languages/i18n";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// scroll bar
import "simplebar/src/simplebar.css";

// load mock apis

// project import
import App from "./App";
import { store } from "store";
import { ConfigProvider } from "contexts/ConfigContext";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>,
  document.getElementById("root")
);
