// authUtils.js

import Cookies from "js-cookie";

// Function to set cookies
export const setAuthCookies = (response) => {
  const { access_token, refresh_token, user_data, expires_in } = response;
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + expires_in * 60 * 1000); // Convert expiresInMinutes to milliseconds and add to the current timestamp
  Cookies.set("gorilla-jobs-access_token", access_token, {
    expires: expirationDate,
  });
  Cookies.set("gorilla-jobs-refresh_token", refresh_token, { expires: 3 });
  Cookies.set("gorilla-jobs-user_data", JSON.stringify(user_data), {
    expires: 3,
  });
  Cookies.set("gorilla-jobs-role", user_data.account_type, { expires: 3 });
};

export const setAccessToken = (accessToken, expiresIn) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + expiresIn * 1000); // expiresIn is in seconds
  Cookies.set("gorilla-jobs-access_token", accessToken, {
    expires: expirationDate,
  });
};

// Function to remove cookies and logout
export const removeAuthCookies = () => {
  Cookies.remove("gorilla-jobs-access_token");
  Cookies.remove("gorilla-jobs-refresh_token");
  Cookies.remove("gorilla-jobs-user_data");
  Cookies.remove("gorilla-jobs-role");
};

// Function to get user ID and full name
export const getUserIdAndName = () => {
  const userDataString = Cookies.get("gorilla-jobs-user_data");
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    const { id, fullname } = userData;
    return { userId: id, fullName: fullname };
  }
  return { userId: null, fullName: null };
};

// Function to get access token
export const getAccessToken = () => {
  return Cookies.get("gorilla-jobs-access_token") || null;
};

// Function to get refresh token
export const getRefreshToken = () => {
  return Cookies.get("gorilla-jobs-refresh_token") || null;
};

export const getUserRole = () => {
  const userDataString = Cookies.get("gorilla-jobs-user_data");
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    const { account_type } = userData;
    return account_type;
  }
  return null;
};
