import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"
import {
  Grid,
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  ListSubheader,
  Typography,
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { startOfDay } from "date-fns"

const MyInput = ({
  label,
  type,
  options,
  defaultValue,
  placeholder,
  md = 6,
  sm = 12,
  lg = 4,
  keyKey = "id",
  valueKey = "id",
  labelKey = "name",
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name)
  //const [value, setValue] = React.useState("")

  const handleChange = (value) => {
    helpers.setValue(value)
  }

  // Calculate today's date
  const today = startOfDay(new Date())
  let inputComponent = null

  switch (type) {
    case "text":
      // Text input component
      inputComponent = (
        <TextField
          fullWidth
          {...field}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          placeholder={placeholder}
        />
      )
      break
    case "number":
      // Number input component
      inputComponent = (
        <TextField
          fullWidth
          type="number"
          {...field}
          {...props}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          placeholder={placeholder}
        />
      )
      break
    case "select":
      // Select input component
      inputComponent = (
        <div>
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            variant="outlined"
            select
            {...field}
            {...props}
            required
            error={meta.touched && meta.error}
            helperText={meta.touched && meta.error}
            label={label}
            // value={value}
          >
            <MenuItem value="" disabled>
              <em>{`Select ${label}`}</em>
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option[keyKey]} value={option[valueKey]}>
                {option[labelKey]}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )
      break
    case "DistrictSelect":
      // Select input component for districts grouped by division
      inputComponent = (
        <div>
          <TextField
            sx={{ mb: 2, width: "100%" }}
            fullWidth
            variant="outlined"
            select
            {...field}
            {...props}
            required
            error={meta.touched && meta.error}
            helperText={meta.touched && meta.error}
            label={label}
          >
            <MenuItem value="default" disabled>
              <em>{`Select ${label}`}</em>
            </MenuItem>
            {options?.flatMap((div) =>
              div.districts?.map((option) => (
                <MenuItem key={option[keyKey]} value={option[valueKey]}>
                  {option[labelKey]}
                </MenuItem>
              ))
            )}
          </TextField>
        </div>
      )
      break

    case "date":
      // Date picker input component
      inputComponent = (
        // <LocalizationProvider dateAdapter={AdapterDateFns}>
        //   <DatePicker
        //     sx={{ width: "100%" }}
        //     {...field}
        //     {...props}
        //     value={field.value || null}
        //     onChange={handleChange}
        //     format="dd/MM/yyyy"
        //     fullWidth
        //   />
        // </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            sx={{ width: "100%" }}
            {...field}
            {...props}
            value={field.value || null}
            onChange={handleChange}
            minDate={today} // Set minimum date to today
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={meta.touched && meta.error}
                helperText={meta.touched && meta.error}
              />
            )}
          />
        </LocalizationProvider>
      )
      break
    case "checkbox":
      // Checkbox input component
      inputComponent = (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              {...props}
              checked={field.value}
              onChange={(e) => field.onChange(e)}
            />
          }
          label={label}
        />
      )
      break
    case "radio":
      // Radio button input component
      inputComponent = (
        <RadioGroup {...field} {...props} row>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      )
      break
    default:
      // Default text input component
      inputComponent = (
        <TextField
          fullWidth
          {...field}
          {...props}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          placeholder={placeholder}
        />
      )
      break
  }

  return (
    <Grid item xs={12} sm={sm} md={md} lg={lg}>
      {type !== "checkbox" && (
        <InputLabel sx={{ textTransform: "uppercase", fontWeight: 600, mb: 1 }}>
          {label}
        </InputLabel>
      )}
      {inputComponent}
    </Grid>
  )
}

MyInput.propTypes = {
  label: PropTypes.string.isRequired, // Label for the input field (required)
  type: PropTypes.oneOf([
    "text",
    "number",
    "select",
    "date",
    "checkbox",
    "radio",
    "DistrictSelect", // Add DistrictSelect to the allowed types
  ]), // Type of input field
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired, // Value of the option (required)
      label: PropTypes.string.isRequired, // Label for the option (required)
    })
  ), // Array of options for select or radio inputs
  md: PropTypes.number, // Number of columns for medium-sized screens (default: 6)
  sm: PropTypes.number, // Number of columns for small-sized screens (default: 12)
  lg: PropTypes.number, // Number of columns for large-sized screens (default: 4)
  // Add other prop types as needed
}

export default MyInput
