import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { callApi, selectApi } from "store/reducers/apiSlice";
import { UrlBuilder } from "helpers/UrlBuilder";
import JobFilter from "./JobFilter";
import JobDetails from "./JobDetails";
import { getAccessToken, getUserRole } from "helpers/AuthUtils";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuth from "hooks/useAuth";
import noData from "../../../assets/images/noData.png";

function JobList() {
  const [category, setCategory] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useAuth();
  const role = getUserRole();
  const [searchResults, setSearchResults] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [subCategories, setSubCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { jobCategories, allJobPostsList } = useSelector(selectApi);

  const [search, setSearch] = useState({
    minJobExperience: "",
    minSalary: "",
    maxSalary: "",
    gender: "",
    jobLevel: "",
    selectedJobType: "",
    selectedCategory: "",
    districtId: "",
    subDistrictId: "",
    districtName: "",
    subDistrictName: "",
    category: null,
    clear: false,
    limit: 10,
  });

  function fetchCategoryData() {
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi(`job/categories`),
        output: "jobCategories",
      })
    );
  }

  function fetchData({ clear }) {
    const {
      minJobExperience,
      minSalary,
      maxSalary,
      districtId,
      subDistrictId,
      selectedCategory,
      selectedJobType,
      limit,
    } = search;

    if (!jobCategories || !jobCategories.data) {
      return;
    }

    let matchedJobCategory = jobCategories.data.find(
      (category) => category?.id == id
    );
    setCategory(matchedJobCategory);

    let jobCategoryFilter = matchedJobCategory
      ? matchedJobCategory.sub_categories
        ? matchedJobCategory.sub_categories
            .map((subcategory) => subcategory.id)
            .join(",") +
          "," +
          matchedJobCategory.id
        : matchedJobCategory.id
      : "";

    if (!search?.selectedCategory) {
      setSubCategories(jobCategoryFilter);
      setSearch((prev) => ({
        ...prev,
        selectedCategory: jobCategoryFilter,
      }));
    }

    if (!isLoggedIn || (role !== 1 && role !== 5)) {
      dispatch(
        callApi({
          operationId: UrlBuilder.jobServiceApi(
            `job/posts/list?limit=${limit}&job_type_id=${
              clear ? "" : selectedJobType
            }&district_id=${clear ? "" : districtId}&sub_district_id=${
              clear ? "" : subDistrictId
            }&salary_min=${clear ? "" : minSalary}&salary_max=${
              clear ? "" : maxSalary
            }&job_exp_min=${clear ? "" : minJobExperience}&job_category_ids=${
              selectedCategory ? selectedCategory : jobCategoryFilter
            }`
          ),
          output: "allJobPostsList",
        })
      );
    } else {
      dispatch(
        callApi({
          operationId: UrlBuilder.jobServiceApi(
            `job/posts?limit=${limit}&job_type_id=${
              clear ? "" : selectedJobType
            }&district_id=${clear ? "" : districtId}&sub_district_id=${
              clear ? "" : subDistrictId
            }&salary_min=${clear ? "" : minSalary}&salary_max=${
              clear ? "" : maxSalary
            }&job_exp_min=${clear ? "" : minJobExperience}&job_category_ids=${
              selectedCategory ? selectedCategory : jobCategoryFilter
            }`
          ),
          output: "allJobPostsList",
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (!id) {
      navigate("/search-job");
    }
  }, [id]);

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is still mounted

    const fetchDataAsync = async () => {
      try {
        if (search.limit === 10) {
          setIsLoading(true);
        }

        if (jobCategories) {
          // Fetch initial data if necessary
          await fetchData({ clear: true });
        }

        if (searchResults) {
          // Fetch search results if necessary
          await fetchData({ clear: false });
          if (isMounted) {
            // Only update state if still mounted
            setSearchResults(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading to false after a delay
        setTimeout(() => {
          if (isMounted) {
            // Only update state if still mounted
            setIsLoading(false);
          }
        }, 1000);
      }
    };

    fetchDataAsync();

    // Cleanup function to set the isMounted flag to false
    return () => {
      isMounted = false;
    };
  }, [id, jobCategories, searchResults, isLoggedIn]);

  useEffect(() => {
    fetchCategoryData();
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi("job/levels"),
        output: "jobLevelsList",
      })
    );
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi("job/types"),
        output: "jobTypes",
      })
    );
    dispatch(
      callApi({
        operationId: UrlBuilder.coreServiceApi(`core/location/districts`),
        output: "divisionList",
      })
    );
  }, []);

  const isBelow1223 = useMediaQuery(`(max-width:1223px)`);
  // const isNonMobile = useMediaQuery(`(min-width:900px)`)
  const [isFilerbarOpen, setIsFilerbarOpen] = useState(true);

  // console.log("-----filter bar collapse status:--->", isFilerbarOpen);

  const handleFilterBarOpenClose = () => {
    setIsFilerbarOpen((prev) => !prev);
  };

  // Close the filter bar when screen width is less than 900px
  useEffect(() => {
    if (isBelow1223) {
      setIsFilerbarOpen(false);
    } else {
      setIsFilerbarOpen(true); // Optionally reset when above 900px
    }
  }, [isBelow1223]);

  return (
    <Box position="relative">
      {isFilerbarOpen ? (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bgcolor: "#F4FCF7",
            minWidth: "300px",
            display: `${isBelow1223 ? "flex" : "none"}`,
            justifyContent: "center", // Centers horizontally
            height: "calc(100vh - 80px)",
            overflowY: "scroll",
            zIndex: "1000",
          }}
        >
          <JobFilter
            category={category}
            search={search}
            setSearch={setSearch}
            setSearchResults={setSearchResults}
            subCategories={subCategories}
            setIsFilerbarOpen={setIsFilerbarOpen}
          />
        </Box>
      ) : (
        <Box
          variant="outlined"
          sx={{
            top: ".5rem",
            left: "1.5rem",
            borderRadius: "5px",
            borderColor: "white",
            padding: "5px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            bgcolor: "#D9D9D9",
            position: "absolute",
            zIndex: 100,
          }}
          onClick={() => {
            handleFilterBarOpenClose();
            console.log("---open close button clicked");
          }}
        >
          <FilterAltIcon fontSize="medium" />
          <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
            Filter
          </Typography>
        </Box>
      )}
      <Grid container>
        {isFilerbarOpen && (
          <Grid
            item
            display={isBelow1223 ? "none" : "block"}
            md={3}
            lg={2.5}
            xl={2}
            maxHeight={`calc(100vh - 80px)`}
            overflow={"auto"}
            style={{
              backgroundColor: "primary.main",
              padding: "20px",
              paddingTop: "0px",
            }}
            sx={{
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#DADDDF",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
              // msOverflowStyle: "none",
              pr: 5,
            }}
          >
            <JobFilter
              category={category}
              search={search}
              setSearch={setSearch}
              setSearchResults={setSearchResults}
              subCategories={subCategories}
              setIsFilerbarOpen={setIsFilerbarOpen}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={`${isBelow1223 || !isFilerbarOpen ? 12 : 9}`}
          lg={`${isBelow1223 || !isFilerbarOpen ? 12 : 9.5}`}
          xl={`${isBelow1223 || !isFilerbarOpen ? 12 : 10}`}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 80px)",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {role == 1 || role == 5 ? (
                <>
                  {allJobPostsList?.data?.job_posts?.length > 0 ? (
                    <JobDetails
                      category={category}
                      setRefetch={setRefetch}
                      setSearch={setSearch}
                      setSearchResults={setSearchResults}
                      search={search}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={noData}
                        alt="No job posts available"
                        style={{ width: "300px", height: "auto" }}
                      />
                      <Typography variant="h1">No Data Found!</Typography>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {allJobPostsList?.data?.public_job_posts?.length > 0 ? (
                    <JobDetails
                      category={category}
                      setRefetch={setRefetch}
                      setSearch={setSearch}
                      setSearchResults={setSearchResults}
                      search={search}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={noData}
                        alt="No job posts available"
                        style={{ width: "300px", height: "auto" }}
                      />
                      <Typography variant="h1">No Data Found!</Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default JobList;
