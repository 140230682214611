// // i18n.js
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import translationEN from "../assets/Languages/english.json";
// import translationBN from "../assets/Languages/bangla.json";

// i18n.use(initReactI18next).init({
//   resources: {
//     en: {
//       translation: translationEN,
//     },
//     bn: {
//       translation: translationBN,
//     },
//   },
//   lng: "en", // Default language
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n;

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import english from "../assets/Languages/english.json";
import bangla from "../assets/Languages/bangla.json";

i18next.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: english,
    bn: bangla,
  },
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
