import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import Category from "pages/admin/job-category/Category";
const MainLayout = Loadable(lazy(() => import("layout/MainLayout")));
const AuthGuard = Loadable(lazy(() => import("utils/route-guard/AuthGuard")));

const JobCategory = Loadable(
  lazy(() => import("pages/admin/job-category/JobCategory"))
);
const CandidateAppliedJobs = Loadable(
  lazy(() => import("pages/admin/candidates/CandidateAppliedJobs"))
);
const ExpiredJobs = Loadable(
  lazy(() => import("pages/admin/expired-jobs/ExpiredJobs"))
);
const AddCategory = Loadable(
  lazy(() => import("pages/admin/job-category/AddCategory"))
);
const PendingJobs = Loadable(
  lazy(() => import("pages/admin/pending-jobs/PendingJobs"))
);
const JobApprovalSetting = Loadable(
  lazy(() =>
    import("pages/admin/settings/JobApprovalSetting/JobApprovalSetting")
  )
);
const RejectedJobs = Loadable(
  lazy(() => import("pages/admin/rejected-jobs/RejectedJobs"))
);
const Sales = Loadable(lazy(() => import("pages/admin/sales/Sales")));
const Dashboard = Loadable(
  lazy(() => import("pages/admin/dashboard/Dashboard"))
);
const Package = Loadable(lazy(() => import("pages/admin/package/Packages")));
const CreatePackage = Loadable(
  lazy(() => import("pages/admin/package/CreatePackage"))
);
const Employers = Loadable(
  lazy(() => import("pages/admin/employers/Employers"))
);
const EmployerDetails = Loadable(
  lazy(() => import("pages/admin/employers/EmployerDetails"))
);
const Candidates = Loadable(
  lazy(() => import("pages/admin/candidates/Candidates"))
);
const CandidateDeatils = Loadable(
  lazy(() => import("pages/admin/candidates/CandidateDetails"))
);

const Jobs = Loadable(lazy(() => import("pages/admin/job/Jobs")));
const JobDetails = Loadable(lazy(() => import("pages/admin/job/JobDetails")));
const JobApplicants = Loadable(
  lazy(() => import("pages/admin/job/JobApplicants"))
);
const ChatHistory = Loadable(lazy(() => import("pages/admin/job/ChatHistory")));
const CvRequests = Loadable(
  lazy(() => import("pages/admin/cv-requests/CvRequests"))
);
const CreateOrder = Loadable(
  lazy(() => import("pages/admin/order/CreateOrder"))
);
const CreateResume = Loadable(
  lazy(() => import("pages/admin/resume/CreateResume"))
);
const BasePackage = Loadable(
  lazy(() => import("pages/admin/base-package/BasePackage"))
);

const AdminRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "order-list",
          element: <Sales />,
        },

        {
          path: "package",
          element: <Package />,
        },
        {
          path: "add-package",
          element: <CreatePackage />,
        },
        {
          path: "add-category",
          element: <AddCategory type="category" />,
        },
        {
          path: "add-subcategory",
          element: <AddCategory type="sub category" />,
        },
        {
          path: "employers",
          element: <Employers />,
        },
        {
          path: "employers/:id",
          element: <EmployerDetails />,
        },
        {
          path: "candidates/:id",
          element: <CandidateDeatils />,
        },
        {
          path: "candidates/applied-jobs/:id",
          element: <CandidateAppliedJobs />,
        },
        {
          path: "pending-jobs",
          element: <PendingJobs />,
        },
        {
          path: "rejected-jobs",
          element: <RejectedJobs />,
        },
        {
          path: "job-approval-settings",
          element: <JobApprovalSetting />,
        },
        {
          path: "candidates",
          element: <Candidates />,
        },

        {
          path: "jobs",
          element: <Jobs />,
        },
        {
          path: "expired-jobs",
          element: <ExpiredJobs />,
        },
        {
          path: "job-category",
          element: <JobCategory />,
        },
        {
          path: "jobs/job-details/:id",
          element: <JobDetails />,
        },
        {
          path: "jobs/job-applicants/:id/:user_id",
          element: <JobApplicants />,
        },
        {
          path: "chat/:id",
          element: <ChatHistory />,
        },
        {
          path: "cv-requests",
          element: <CvRequests />,
        },
        {
          path: "create-cv/:id",
          element: <CreateResume />,
        },
        {
          path: "create-order",
          element: <CreateOrder />,
        },
        {
          path: "base-packages",
          element: <BasePackage />,
        },
        {
          path: "category",
          element: <Category />,
        },
      ],
    },
  ],
};

export default AdminRoutes;
