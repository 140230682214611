import React, { useState } from "react";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import axios from "axios";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { useDispatch } from "react-redux";
import { UrlBuilder } from "helpers/UrlBuilder";
import { useNavigate } from "react-router";

const ImageUploadComponent = ({ id, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const accessToken = Cookies.get("gorilla-jobs-access_token");
  // const userData = Cookies.get('gorilla-jobs-user_data');

  const formik = useFormik({
    initialValues: {
      image: "",
    },
    onSubmit: async (values) => {
      // Here you can implement the logic to send the image to the server

      // handleSubmit(values.image)
      if (values?.image) {
        try {
          let formData = new FormData();
          let uploadedImg = values?.image;
          formData.append("image", uploadedImg);
          const { data } = await axios.put(
            UrlBuilder.jobServiceApi(`job/categories/s3/${id}`),
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          //   setFieldValue("image", data?.data?.url);
          values.image = data?.data?.url;
        } catch (error) {
          //   setFieldValue("image", "");
          values.image = "";
        }
        // navigate("/category")
        handleCloseDialog();
      }
    },
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("image", file);

    // Preview the image
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Container sx={{ minHeight: "400px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} alignItems="center" mb={4}>
          <Grid item xs={6}>
            <TextField
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" type="submit">
              Upload Image
            </Button>
          </Grid>
        </Grid>
      </form>

      <Stack alignItems="center" justifyContent="center" component={Card}>
        <div style={{ width: "300px", height: "300px", overflow: "hidden" }}>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </div>
      </Stack>
    </Container>
  );
};

export default ImageUploadComponent;
