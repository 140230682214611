import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Typography } from "@mui/material";
import CVRequestModalInput from "pages/search/Cv/CVRequestModalInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 600,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

function CvRequestModal({
  open,
  handleClose,
  message,
  setMessage,
  handleSendRequest,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          style={{
            textAlign: "center",
            borderBottom: "1px solid #ccc",
            marginBottom: 20,
          }}
        >
          <Typography variant="h4" fontSize={30} color="primary.main">
            Request Contact
          </Typography>
        </Box>
        <Typography mb={1} variant="h4" component="h2">
          Write a message (Optional)
        </Typography>
        <CVRequestModalInput message={message} setMessage={setMessage} />
        <Typography mb={1} variant="h6">
          By sending contact request you will be deducted 1 CvRequest Balance
          from your current balance.
        </Typography>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ width: 180, marginRight: 5 }}
            variant="contained"
            onClick={() => {
              handleSendRequest();
            }}
          >
            SEND REQUEST
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CvRequestModal;
