import React, { useCallback, useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Avatar from "@mui/material/Avatar"
import { makeStyles } from "@mui/styles"
import HomeIcon from "@mui/icons-material/Home"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import useAuth from "hooks/useAuth"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import axios from "axios"
import { UrlBuilder } from "helpers/UrlBuilder"
import Stack from "@mui/material/Stack"
import useMediaQuery from "@mui/material/useMediaQuery"
import { getUserRole } from "helpers/AuthUtils"
import { useSelector } from "react-redux"
import { selectApi } from "store/reducers/apiSlice"

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "space-between",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #00D42A33",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    "&:hover": {
      "&:hover": { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" },
      cursor: "pointer",
    },
  },
  profileDetails: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      alignItems: "flex-start",
      textAlign: "left",
      marginTop: 0,
    },
  },
  iconContainer: {
    borderRadius: "25px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    margin: 20,
  },
  icon: {
    fontSize: "40px",
    color: "#6BD425",
  },
  textContainer: {
    color: "#000000",
    flex: 1,
    width: "250px",
  },
  favoritesContainer: {
    display: "flex",
    alignItems: "center",
    width: "120px",
    height: "48px",
  },
  favorites: {
    width: "50px",
    height: "30px",
    alignItems: "right",
    justifyContent: "right",
    textAlign: "right",
  },
  locationContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: "2px",
  },
  rightSection: {
    // display: "flex",
    // flexDirection: "column",
    // textAlign: "right",
    // marginTop: "10px",
    // marginLeft: "70px",
    // [theme.breakpoints.down("sm")]: {
    //   alignItems: "flex-end",
    //   textAlign: "right",
    //   marginTop: 0,
    // },
  },
}))

const CvCard = ({
  heading,
  headingAfterLogin,
  subheading,
  image,
  education,
  job_exp,
  organization,
  // color = "#ffffff",
  // status,
  data,
}) => {
  const classes = useStyles()
  const [star, setStar] = useState(false)
  const isLoggedIn = useAuth()
  const role = getUserRole()
  const [districtData, setDistrictData] = useState([])
  const name = heading ? heading.split(" ") : []
  const firstName = name[0] || ""
  const lastName = name.slice(1).join(" ")
  const { divisionList } = useSelector(selectApi)

  useEffect(() => {
    let isMounted = true

    const fetchDistrictData = async () => {
      try {
        const response = await axios.get(
          UrlBuilder.coreServiceApi(
            `core/location/districts/${data?.address?.district_id}/sub_districts`
          )
        )
        if (isMounted) {
          setDistrictData(response.data.data)
        }
      } catch (error) {
        console.error("Error fetching division data:", error)
      }
    }

    if (isLoggedIn) {
      fetchDistrictData()
    }

    return () => {
      isMounted = false
    }
  }, [isLoggedIn, data?.address?.district_id])

  function findDistrictById(districtId) {
    for (const region of divisionList?.data ?? []) {
      const district = region?.districts?.find((d) => d.id === districtId)
      if (district) {
        return district.name
      }
    }
    return null
  }

  const findSubdistrict = districtData?.find(
    (subdistrict) => subdistrict?.id === data?.address?.sub_district_id
  )?.name

  const isBelow400 = useMediaQuery(`(max-width:477px)`)

  return (
    <Card className={classes.card}>
      <Grid container spacing={1}>
        {/* Image */}
        <Grid item xs={2} lg={2}>
          {image ? (
            <Box
              component="img"
              alt={name}
              src={image}
              sx={{
                width: "2.5rem",
                height: "2.5rem",
                color: "#006D41",
                borderRadius: "50%",
                background: "#92E6B5",
                zIndex: 0,
              }}
            />
          ) : (
            <HomeIcon
              fontSize="large"
              sx={{ fontSize: "40px", color: "primary.main" }}
            />
          )}
        </Grid>

        {/* Proifle and Edu section */}
        <Grid item container xs={10} lg={10}>
          {/* Profile Info section */}
          <Grid item xs={12} sm={6} md={6} lg={7}>
            <Box className={classes.profileDetails}>
              {!isLoggedIn || (role !== 4 && role !== 5) ? (
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography variant="h6" fontSize={"15px"} fontWeight={700}>
                    {firstName}&nbsp;
                  </Typography>
                  {lastName && (
                    <Typography
                      variant="h6"
                      fontSize={"15px"}
                      fontWeight={700}
                      style={{
                        filter: "blur(3px)",
                        pointerEvents: "none",
                        userSelect: "none",
                      }}
                    >
                      {lastName}
                    </Typography>
                  )}
                </Box>
              ) : (
                <Typography variant="h6" fontSize={"15px"} fontWeight={700}>
                  {headingAfterLogin}
                </Typography>
              )}
              <Typography
                variant="body2"
                fontSize={"12px"}
                sx={{ color: "#808481" }}
              >
                {subheading}
              </Typography>
              <Typography
                variant="body2"
                fontSize={"11px"}
                sx={{ color: "#808481" }}
              >
                {organization}
              </Typography>
              {isLoggedIn && role === 4 ? (
                <Box className={classes.locationContainer}>
                  <LocationOnIcon
                    sx={{ color: "#676C68", width: "16px", height: "16px" }}
                  />
                  <Typography
                    variant="body2"
                    fontSize={"11px"}
                    sx={{
                      color: "#808481",
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      maxWidth: "100%",
                    }}
                  >
                    {`${findSubdistrict}, ${findDistrictById(
                      data?.address?.district_id
                    )}`.length > 50
                      ? `${`${findSubdistrict}, ${findDistrictById(
                          data?.address?.district_id
                        )}`.substring(0, 30)}...`
                      : `${findSubdistrict}, ${findDistrictById(
                          data?.address?.district_id
                        )}`}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Grid>

          {/* Education Info section */}
          <Grid item xs={12} sm={6} md={6} lg={5}>
            {education && (
              <Box
                className={classes.rightSection}
                sx={{
                  // minWidth: "210px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "3px",
                  alignItems: `${isBelow400 ? "start" : "end"}`,
                  marginLeft: `${isBelow400 ? "15px" : ""}`,
                }}
              >
                <Typography
                  variant="body2"
                  fontSize={"11px"}
                  sx={{
                    background: "#DADDDFB8",
                    padding: "3px 10px 3px 10px",
                    fontWeight: "700",
                    borderRadius: "4px",
                    width: "fit-content",
                    textWrap: "nowrap",
                    whiteSpace: "nowrap",
                  }}
                >
                  {education}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={"11px"}
                  sx={{
                    background: "#DADDDFB8",

                    padding: "3px 10px 3px 10px",
                    fontWeight: "700",
                    borderRadius: "4px",
                    width: "fit-content",
                    textWrap: "nowrap",
                    whiteSpace: "nowrap",
                  }}
                >
                  {job_exp + "+years exp."}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CvCard
