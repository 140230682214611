import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import { ReactComponent as AcceptedIcon } from "../../../assets/accepted.svg";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

function Dashboard() {
  const [JobPost, setJobPosts] = useState(true);
  const [CVRequest, setCVRequest] = useState(true);
  const theme = useTheme();
  const isBellow1030px = useMediaQuery("(max-width: 1330px)");
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        transition: "width 0.3s",
        //paddingTop: isMobile ? "10rem" : "4.5rem",
        paddingTop: {
          xl: "1.5rem",
          lg: isBellow1030px ? "10.8rem" : "1.5rem",
          md: "10.8rem",
          sm: "10.8rem",
          xs: "10.8rem",
        },
        boxShadow: "rgba(0, 0, 0, 0.06)",
      }}
    >
      <Box>
        <List>
          <ListItem
            button
            component={Link}
            to="/dashboard"
            sx={{
              gap: "0.375rem",
              ".&MuiListItem-root": {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <ListItemIcon>
              <HomeIcon
                sx={{ color: "#00D42A", width: "1.5rem", height: "1.5rem" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{
                ".css-fvmypa-MuiTypography-root": {
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "#242823",
                },
              }}
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            sx={{
              gap: "0.375rem",
              ".&MuiListItem-root": {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <ListItemIcon>
              <AcceptedIcon sx={{ width: "1.5rem", height: "1.5rem" }} />
            </ListItemIcon>
            <ListItemText
              primary="Packages"
              sx={{
                ".css-fvmypa-MuiTypography-root": {
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  color: "#242823",
                },
              }}
              secondary="Offers and Bundles"
            />
          </ListItem>
          <Divider />

          <ListItem
            button
            onClick={() => navigate("/post-new-job")}
            sx={{ gap: "0.375rem" }}
          >
            <ListItemIcon>
              <ChevronRightRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Post New Job" />
          </ListItem>
          <ListItem
            button
            sx={{ gap: "0.375rem" }}
            onClick={() => setJobPosts(!JobPost)}
          >
            <ListItemIcon>
              {JobPost ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>
            <ListItemText primary="Job Post" />
          </ListItem>
          <Collapse in={JobPost} timeout={"auto"} unmountOnExit>
            <ListItem
              button
              onClick={() => navigate("/jobs", { state: { tabValue: 0 } })}
              sx={{ pl: 6 }}
            >
              <ListItemText primary="Active Jobs" />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate("/jobs", { state: { tabValue: 1 } })}
              sx={{ pl: 6 }}
            >
              <ListItemText primary="Expired Jobs" />
            </ListItem>
            <ListItem
              button
              onClick={() => navigate("/jobs", { state: { tabValue: 2 } })}
              sx={{ pl: 6 }}
            >
              <ListItemText primary="Draft Posts" />
            </ListItem>
          </Collapse>

          <ListItem
            button
            sx={{ gap: "0.375rem" }}
            onClick={() => setCVRequest(!CVRequest)}
          >
            <ListItemIcon>
              {CVRequest ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>
            <ListItemText primary="CV Requests" />
          </ListItem>
          <Collapse in={CVRequest} timeout={"auto"} unmountOnExit>
            <ListItem button component={Link} to="/cv-requests" sx={{ pl: 6 }}>
              <ListItemText primary="All CV Requests" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/request-acepted"
              sx={{ pl: 6 }}
            >
              <ListItemText primary="Accepted CV Requests" />
            </ListItem>
          </Collapse>
        </List>
      </Box>
    </Box>
  );
}

export default Dashboard;
