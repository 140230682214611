import { Box, Typography } from "@mui/material";
import { getUserRole } from "helpers/AuthUtils";
import useAuth from "hooks/useAuth";
import React from "react";

function JobDescription({ allJobPostsList, selectJobIndex, useStyles }) {
  const classes = useStyles();
  const isLoggedIn = useAuth();
  const role = getUserRole();
  return (
    <>
      {isLoggedIn && (role === 1 || role === 5) ? (
        <Box style={{ pl: 1, pr: 3 }}>
          <Box ml={2}>
            <Typography
              variant="h4"
              className={classes.heading}
              sx={{ fontSize: 20, color: "#010A03" }}
            >
              {
                allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                  ?.employer?.name
              }
            </Typography>
            <Typography variant="body1" sx={{ color: "#010A03", fontSize: 15 }}>
              {
                allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                  ?.address?.area
              }
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#010A03", fontSize: 15, fontWeight: 600 }}
            >
              Job Description:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontSize: 15,
                textAlign: "justify",
              }}
            >
              {
                allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                  ?.description
              }
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box style={{ pl: 1, pr: 3 }}>
          <Box ml={2}>
            <Typography
              variant="h4"
              className={classes.heading}
              sx={{ fontSize: 20, color: "#010A03" }}
            >
              {
                allJobPostsList?.data?.public_job_posts[selectJobIndex]
                  ?.organization
              }
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "secondary.main", fontSize: 15 }}
            >
              {allJobPostsList?.data?.public_job_posts[selectJobIndex]?.address}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#010A03", fontSize: 15, fontWeight: 600 }}
            >
              Job Description:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontSize: 15,
                textAlign: "justify",
              }}
            >
              {
                allJobPostsList?.data?.public_job_posts[selectJobIndex]
                  ?.description
              }
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

export default JobDescription;
