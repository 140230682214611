import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import CVRequestModalInput from "../Cv/CVRequestModalInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 600,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

function ApplyModal({
  message,
  open,
  handleClose,
  selectJobIndex,
  allJobPostsList,
  setMessage,
  handleApplyJob,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          style={{
            textAlign: "center",
            borderBottom: "1px solid #ccc",
            marginBottom: 20,
          }}
        >
          <Typography variant="h4" fontSize={30} color="primary.main">
            Apply Job
          </Typography>
        </Box>
        <Typography mb={1} variant="h4" component="h2">
          Write a message (Optional)
        </Typography>
        <Typography mb={1} variant="h4" component="h2">
          {allJobPostsList?.data?.job_posts[selectJobIndex].job_post.question}
        </Typography>
        <CVRequestModalInput message={message} setMessage={setMessage} />

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ width: 180, marginRight: 5 }}
            variant="contained"
            onClick={() => {
              handleApplyJob();
              handleClose();
            }}
          >
            APPLY
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ApplyModal;
