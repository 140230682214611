import React from "react"
import { Stack, Button, useMediaQuery } from "@mui/material"

const DetailsMenu = ({ moreCvDetails, setMoreCvDetails }) => {
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const menuItems = [
    { label: "Experience", value: "Experience" },
    { label: "Education", value: "Education" },
    { label: "License/Certificate", value: "Certificate" },
  ]

  return (
    <Stack direction="row" spacing={2}>
      {menuItems.map((item) => (
        <Button
          key={item.value}
          onClick={() => setMoreCvDetails(item.value)}
          sx={
            moreCvDetails === item.value
              ? {
                  fontStyle: "bold",
                  borderBottom: 2,
                  borderRadius: 0,
                  fontSize: isExtraSmall ? "12px" : "17px",
                  fontWeight: 600,
                  color: "#355E79",
                  backgroundColor: "transparent",
                }
              : {
                  color: "#A7A7A7",
                  fontSize: isExtraSmall ? "12px" : "17px",
                  fontWeight: 400,
                  backgroundColor: "transparent",
                }
          }
        >
          {item.label}
        </Button>
      ))}
    </Stack>
  )
}

export default DetailsMenu
