import React from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import CvCard from "pages/search/Cv/CvCard"
import portalImage from "../../assets/images/portal/mockup.jpg"
import InfiniteScroll from "react-infinite-scroll-component"
import Shimmer from "pages/search/Job/Shimmer"
import { useSelector } from "react-redux"
import { selectApi } from "store/reducers/apiSlice"
import useAuth from "hooks/useAuth"
import { getUserRole } from "helpers/AuthUtils"

export const CvListSimpleBar = ({
  setSearchResults,
  search,
  setSearch,
  data,
  showAllCategories,
  findCategoriesName,
  findEducation,
  handleCardClick,
  selectCvIndex,
}) => {
  const { allResumeList } = useSelector(selectApi)
  const isLoggedIn = useAuth()
  const role = getUserRole()
  const loadMoreData = () => {
    setSearch((prev) => ({
      ...prev,
      limit: search.limit + 10,
    }))
    setSearchResults(true)
  }

  return (
    <Box sx={{ width: "100%" }}>
      {data?.resumes?.length === 0 ? (
        <Box sx={{ alignItems: "center", textAlign: "center", margin: 30 }}>
          <Typography variant="h2" fontSize={30} color="primary.main">
            No Data
          </Typography>
        </Box>
      ) : (
        <InfiniteScroll
          dataLength={allResumeList?.data?.resumes?.length || 0} //This is important field to render the next data
          hasMore={
            allResumeList?.data?.total > allResumeList?.data?.resumes?.length
          }
          // dataLength={showAllCategories ? data?.length : 10}
          next={() => {
            setSearch((prev) => ({ ...prev, limit: search.limit + 10 }))
            setSearchResults(true)
          }}
          loader={<Shimmer />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          scrollableTarget="scrollableDiv"
        >
          {data
            ?.slice(0, showAllCategories ? data?.length : 12)
            .map((item, index) => {
              const organization = item.experiences
                ? item.experiences[0].organization_name
                : null

              return (
                <Box
                  key={`EmployeeCard - ${index}`}
                  onClick={() => handleCardClick(index)}
                  sx={{
                    border:
                      selectCvIndex === index ? "2px solid #39FF14" : "none",
                    borderRadius: "8px",
                    marginBottom: "1rem",
                  }}
                >
                  <CvCard
                    heading={item?.full_name}
                    headingAfterLogin={item?.fullname || item?.full_name}
                    image={
                      item?.profile_image === ""
                        ? portalImage
                        : item?.profile_image
                    }
                    subheading={findCategoriesName(item?.category_id)}
                    education={findEducation(item?.edu_qualification_id)}
                    job_exp={item?.job_exp}
                    organization={organization}
                    data={item}
                  />
                </Box>
              )
            })}
        </InfiniteScroll>
      )}
    </Box>
  )
}
