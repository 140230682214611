import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { UrlBuilder } from "helpers/UrlBuilder";
import { getAccessToken } from "helpers/AuthUtils";

const validationSchema = Yup.object().shape({
  image: Yup.string().url("Invalid URL"),
});

const UpdateImageUrl = ({ id }) => {
  const initialValues = {
    image: "",
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const accessToken = getAccessToken(); // Replace with your actual access token

  const handleSubmit = async (values) => {
    try {
      const response = await axios.put(
        UrlBuilder.jobServiceApi(`job/categories/${id}`),
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setIsSuccess(true);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <h2>Update Image Link</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <Field name="image">
            {({ field, meta }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                fullWidth
                label="Image Link"
                sx={{ mt: 2 }}
              />
            )}
          </Field>
          <ErrorMessage name="image" component="div" />

          <Button sx={{ mt: 2 }} variant="contained" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={isSuccess ? "success" : "error"}
          variant="filled"
          icon={
            isSuccess ? <CheckCircleOutlineIcon fontSize="inherit" /> : null
          }
        >
          {isSuccess ? "Success!" : "Error"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default UpdateImageUrl;
