import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useAuth from "hooks/useAuth";
import { makeStyles } from "@mui/styles";
import React from "react";
import moment from "moment";

function ExperienceCard({ data, jobTypes, role }) {
  const isLoggedIn = useAuth();

  const useStyles = makeStyles({
    nameContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      Padding: "20px",
      flex: 2,
    },
    iconContainer: {
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100px",
      height: "100px",
    },
    icon: {
      fontSize: "40px", // Adjust the icon size as needed
      color: "#6BD425", // Use the specified icon color
    },
    textContainer: {
      color: "#000000",
    },
    favoritesContainer: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
    },
    favorites: {
      textAlign: "left",
    },
  });
  const classes = useStyles();
  return (
    <>
      {!isLoggedIn || role !== 4 ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            Padding: "20px",
            position: "relative",
            flex: 2,
          }}
        >
          <Box
            style={{
              borderRadius: "50px",
              display: "flex",
              width: "30px",
              height: "100%",
            }}
          >
            <Box
              style={{
                width: "16px",
                height: "16px",
                position: "absolute",
                top: 7,
                left: 0,
                backgroundColor: "#00D42A",
                borderRadius: "8px",
              }}
            />
            <Box
              style={{
                width: 3,
                height: "100%",
                position: "absolute",
                top: 7,
                left: 7,
                backgroundColor: "#00D42A",
              }}
            />
          </Box>
          <Box ml={2}>
            <Typography
              variant="h4"
              className={classes.heading}
              sx={{ fontSize: 17, fontWeight: 600, color: "#010A03" }}
            >
              {data.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#010A03", fontSize: 13, fontWeight: 400 }}
            >
              {data.organization}
            </Typography>
            <Typography variant="body1" sx={{ color: "#696969", fontSize: 13 }}>
              {data.city} {data.country}
            </Typography>
            <Typography variant="body1" sx={{ color: "#696969", fontSize: 13 }}>
              {jobTypes?.data?.find((e) => e?.id == data?.job_type_id)?.name} |{" "}
              {moment(data.start_date).format("ll")} -{" "}
              {data.end_date ? moment(data.end_date).format("ll") : "Current"}
            </Typography>

            <Typography
              variant="body1"
              sx={{ color: "#696969", fontSize: 15 }}
              mt={1}
            >
              {data.description}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          {data.type === 3 && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                Padding: "20px",
                position: "relative",
                flex: 2,
              }}
            >
              <Box
                style={{
                  borderRadius: "50px",
                  display: "flex",
                  width: "30px",
                  height: "100%",
                }}
              >
                <Box
                  style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    top: 7,
                    left: 0,
                    backgroundColor: "#00D42A",
                    borderRadius: "8px",
                  }}
                />
                <Box
                  style={{
                    width: 3,
                    height: "100%",
                    position: "absolute",
                    top: 7,
                    left: 7,
                    backgroundColor: "#00D42A",
                  }}
                />
              </Box>
              <Box ml={2}>
                <Typography
                  variant="h4"
                  className={classes.heading}
                  sx={{ fontSize: 20 }}
                >
                  {data.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#010A03", fontSize: 13 }}
                >
                  {data.organization}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#696969", fontSize: 13 }}
                >
                  {data.city} {data.country}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#696969", fontSize: 13 }}
                >
                  {/* {jobTypes?.data?.find((e) => e.id == data.job_type_id).name} |{" "} */}
                  {moment(data.start_date).format("ll")} -{" "}
                  {data.end_date
                    ? moment(data.end_date).format("ll")
                    : "Current"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#696969", fontSize: 15 }}
                  mt={1}
                >
                  {data.description}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default ExperienceCard;
