// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import "./styles.css";
import Toaster from "components/Toaster";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { callApi } from "store/reducers/apiSlice";
import { UrlBuilder } from "helpers/UrlBuilder";
// auth provider

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      callApi({
        operationId: UrlBuilder.coreServiceApi(`core/notification`),
        output: "notification",
      })
    );
  }, []);
  return (
    <ThemeCustomization>
      <Routes />
      <Toaster />
    </ThemeCustomization>
  );
};

export default App;
