import { Clear, Search } from "@mui/icons-material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Button,
  MenuItem,
  Stack,
  Typography,
  InputLabel,
  Grid,
  FormControlLabel,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApi, selectApi } from "store/reducers/apiSlice";
import CustomTextField from "components/form/CustomTextField";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { UrlBuilder } from "helpers/UrlBuilder";
import LocationFilter from "./FilterComponents/LocationFilter";
import FilterCategory from "./FilterComponents/FilterCategory";

const BpIcon = styled("span")(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow: "0 0 0 1.5px gray",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#00D42A",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#00a321",
  },
});

const JobFilter = ({
  search,
  setSearch,
  setSearchResults,
  category,
  subCategories,
  setIsFilerbarOpen,
}) => {
  const { jobTypes, divisionList } = useSelector(selectApi);
  const [showSubCat, setShowSubCat] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const dispatch = useDispatch();
  const { subDistricts } = useSelector(selectApi);
  const experience = [
    { value: 1, name: "1 Year" },
    { value: 2, name: "2 Year" },
    { value: 3, name: "3 Year" },
    { value: 4, name: "4 Year" },
    { value: 5, name: "5 Year" },
    { value: 6, name: "6 Year" },
    { value: 7, name: "7 Year" },
    { value: 8, name: "8 Year" },
    { value: 9, name: "9 Year" },
    { value: 10, name: "10 Year" },
    { value: 11, name: "11 Year" },
    { value: 12, name: "12 Year" },
    { value: 13, name: "13 Year" },
    { value: 14, name: "14 Year" },
    { value: 15, name: "15 Year" },
    { value: 16, name: "16 Year" },
    { value: 17, name: "17 Year" },
    { value: 18, name: "18 Year" },
    { value: 19, name: "19 Year" },
    { value: 20, name: "20 Year" },
  ];

  const clearFilter = () => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input) => (input.value = ""));
    setSearch({
      minJobExperience: "",
      minSalary: "",
      maxSalary: "",
      districtId: "",
      subDistrictId: "",
      selectedJobType: "",
      selectedCategory: "",
      job_level_id: null,
      gender: "",
      category: null,
      clear: true,
      limit: 10,
    });
    setSearchResults(true);
  };

  React.useEffect(() => {
    dispatch(
      callApi({
        operationId: UrlBuilder.coreServiceApi(
          `core/location/districts/${search.districtId}/sub_districts`
        ),
        output: "subDistricts",
      })
    );
  }, [search.districtId]);

  const handleCategory = () => {
    setShowSubCat(!showSubCat);
  };

  const salaryMinhandleChange = (event, newValue) => {
    setSearch((prev) => ({ ...prev, minSalary: newValue }));
  };

  const salaryMaxhandleChange = (event, newValue) => {
    setSearch((prev) => ({ ...prev, maxSalary: newValue }));
  };

  const handleCheckboxChange = (id) => {
    setSearch((prev) => ({ ...prev, selectedJobType: id }));
  };
  const handleCloseFilterBar = () => {
    console.log("------handle close filer clicked----");
    setIsFilerbarOpen(false);
  };

  return (
    <Stack
      spacing={2}
      flexDirection={"column"}
      sx={{
        height: "100%",
        paddingTop: "50px",
        paddingLeft: "5px",
        paddingRight: "5px",
        alignContent: "center",
        // overflowY: "scroll",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={300} fontSize={24} color="#444444">
          Search Jobs
        </Typography>
        <Button
          color="primary"
          size="small"
          sx={{ marginLeft: 1, display: "flex", alignItems: "center" }}
          onClick={handleCloseFilterBar}
        >
          <ClearIcon fontSize="small" sx={{ marginRight: 0.5 }} />
        </Button>
      </Box>

      {showSubCat && (
        <FilterCategory
          category={category}
          subCategories={subCategories}
          search={search}
          setSearch={setSearch}
          handleCategory={handleCategory}
        />
      )}
      {!showSubCat ? (
        <>
          {!showLocation && (
            <>
              <Button
                startIcon={<DnsOutlinedIcon />}
                color="primary"
                variant="outlined"
                sx={{
                  gap: 1,
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => {
                  handleCategory();
                }}
              >
                Select sub Category
              </Button>

              <Button
                startIcon={<RoomOutlinedIcon />}
                color="primary"
                variant="outlined"
                sx={{
                  gap: 1,
                  width: "100%",
                  height: "45px",
                }}
                onClick={() => {
                  setShowLocation(!showLocation);
                }}
              >
                Select location
              </Button>
            </>
          )}

          {showLocation && (
            <LocationFilter
              divisionList={divisionList}
              setShowLocation={setShowLocation}
              showLocation={showLocation}
              setSearch={setSearch}
              search={search}
              subDistricts={subDistricts}
            />
          )}

          {!showLocation && (
            <Box
              sx={{
                width: "100%",
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                select
                inputLabel="Minimum Job Experience"
                label="Select years"
                sx={{
                  width: "100%",
                  height: "45px",
                }}
                value={search.minJobExperience}
                onChange={(e) =>
                  setSearch((prev) => ({
                    ...prev,
                    minJobExperience: e.target.value,
                  }))
                }
              >
                {experience?.map((j) => (
                  <MenuItem key={j?.name} value={j?.value}>
                    {j?.name}
                  </MenuItem>
                ))}
              </CustomTextField>

              <Grid>
                <InputLabel
                  sx={{ textTransform: "uppercase", fontWeight: 600 }}
                >
                  Salary range
                </InputLabel>
                <InputLabel
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 500,
                    fontSize: 12,
                    marginTop: 1,
                  }}
                >
                  Min Salary : {search?.minSalary}
                </InputLabel>
                <Grid>
                  <Slider
                    defaultValue={50}
                    aria-label="Default"
                    label="Min"
                    sx={{
                      width: "100%",
                    }}
                    valueLabelDisplay="auto"
                    value={search?.minSalary}
                    min={0}
                    max={200000}
                    onChange={salaryMinhandleChange}
                  />
                </Grid>
                <InputLabel
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: 12,
                    marginTop: 1,
                  }}
                >
                  Max Salary : {search?.maxSalary}
                </InputLabel>
                <Grid>
                  <Slider
                    defaultValue={50}
                    aria-label="Default"
                    label="Max"
                    sx={{ width: "100%" }}
                    valueLabelDisplay="auto"
                    value={search.maxSalary}
                    min={search.minSalary + 10000}
                    max={5000000}
                    onChange={salaryMaxhandleChange}
                  />
                </Grid>
              </Grid>
              <Grid container item sx={{ paddingLeft: "8px" }}>
                <Grid item xs={10}>
                  <InputLabel
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#010A03",
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    Job type
                  </InputLabel>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup>
                    {jobTypes?.data?.slice(0, 2).map((g) => (
                      <FormControlLabel
                        key={`jobTypes - ${g?.id}`}
                        control={
                          <Checkbox
                            checked={search?.selectedJobType === g?.id}
                            onChange={() => handleCheckboxChange(g?.id)}
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                            }}
                            disableRipple
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            inputProps={{ "aria-label": "Checkbox demo" }}
                          />
                        }
                        label={g?.name}
                      />
                    ))}
                  </FormGroup>
                </Grid>

                <Grid item xs={6}>
                  <FormGroup>
                    {jobTypes?.data?.slice(2, 4).map((g) => (
                      <FormControlLabel
                        key={`jobTypes - ${g?.id}`}
                        control={
                          <Checkbox
                            checked={search?.selectedJobType === g?.id}
                            onChange={() => handleCheckboxChange(g?.id)}
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                            }}
                            disableRipple
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            inputProps={{ "aria-label": "Checkbox demo" }}
                          />
                        }
                        label={g?.name}
                      />
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>
              <Button
                startIcon={<Search />}
                sx={{ width: "100%", height: "45px" }}
                variant="contained"
                onClick={() => setSearchResults(true)}
              >
                SEARCH
              </Button>
              <Button
                startIcon={<Clear />}
                color="secondary"
                variant="contained"
                sx={{ width: "100%", height: "45px" }}
                onClick={clearFilter}
              >
                CLEAR FILTER
              </Button>
            </Box>
          )}
        </>
      ) : null}
    </Stack>
  );
};

export default JobFilter;
