import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Input from "@mui/material/Input"
import TextField from "@mui/material/TextField"
import OutlinedInput from "@mui/material/OutlinedInput"
import React, { useMemo } from "react"
import MyInput from "components/form/MyInput"
import { number } from "prop-types"

export const JobPostStep2 = React.memo(
  ({ setFieldValue, values, formOptions, touched, errors }) => {
    const educationQualificationsOptions = useMemo(
      () => [
        // { label: "Select Qualification", value: "default" },
        ...(formOptions?.education_qualifications?.map((type) => ({
          label: type.name,
          value: type.id,
        })) || []),
      ],
      [formOptions]
    )

    // console.log("-----values--2-->", values);

    return (
      <Box>
        <Grid container spacing={2}>
          <MyInput type="number" label="Minimum Age" name="age_min" lg={3} />
          <MyInput type="number" label="Maximum Age" name="age_max" lg={3} />
          <MyInput
            type="number"
            label="Mininum Experience Required"
            name="job_exp_min"
            lg={3}
          />

          <MyInput
            label="Educational Qualification"
            type="select"
            name="edu_qualification_id"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            options={educationQualificationsOptions}
          />
          <MyInput
            label="Educational Requirments"
            name="edu_requirements"
            multiline
            rows={5}
            lg={12}
          />
        </Grid>
      </Box>
    )
  }
)
