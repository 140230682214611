import Grid from "@mui/material/Grid";
import MyInput from "components/form/MyInput";

const JobPostStep5 = () => {
  return (
    <>
      <Grid container spacing={2}>
        <MyInput
          label="Additional Questions"
          name="question"
          multiline
          rows={12}
          lg={12}
          md={12}
          //
        />
      </Grid>
    </>
  );
};

export default JobPostStep5;
