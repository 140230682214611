import {
  Box,
  Button,
  ListSubheader,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomTextField from "components/form/CustomTextField";

function LocationFilter({
  divisionList,
  setShowLocation,
  showLocation,
  setSearch,
  search,
  subDistricts,
}) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
      }}
    >
      <Button
        sx={{
          gap: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 0,
        }}
        onClick={() => {
          setShowLocation(!showLocation);
        }}
      >
        <ArrowBackIosNewIcon />
        <Typography fontSize={21} fontWeight={700}>
          Select Location
        </Typography>
      </Button>
      <CustomTextField
        select
        label="Select District"
        sx={{ width: "100%" }}
        value={search.districtId}
        onChange={(e) => {
          const selectedDistrict = divisionList?.data
            .flatMap((division) => division.districts)
            .find((district) => district.id === e.target.value);

          setSearch((prev) => ({
            ...prev,
            districtId: e.target.value,
            districtName: selectedDistrict?.name || "",
            subDistrictId: "",
          }));
        }}
        inputLabel={
          <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
            District
          </Typography>
        }
      >
        {divisionList?.data?.map((division) => [
          <ListSubheader
            key={division.id}
            sx={{ borderBottom: "1px solid #ccc" }}
          >
            <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
              {division.name}
            </Typography>
          </ListSubheader>,
          ...division.districts?.map((district) => (
            <MenuItem
              key={district.id}
              value={district.id}
              sx={{
                bgcolor:
                  search.districtId === district.id ? "green" : "inherit",
                color: search.districtId === district.id ? "white" : "inherit",
                "&:hover": {
                  bgcolor: "#00D42A",
                  color: "white",
                },
                "&:focus": {
                  bgcolor: "#00D42A",
                  color: "white",
                },
                paddingLeft: "30px",
                fontWeight: "700",
                py: "10px",
              }}
            >
              {district.name}
            </MenuItem>
          )),
        ])}
      </CustomTextField>

      <CustomTextField
        disabled={search.districtId === ""}
        select
        inputLabel={
          <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
            Subdistrict
          </Typography>
        }
        label="Select District"
        sx={{ width: "100%" }}
        value={search.subDistrictId}
        onChange={(e) => {
          const selectedSubDistrict = subDistricts?.data?.find(
            (subDistrict) => subDistrict.id === e.target.value
          );

          setSearch((prev) => ({
            ...prev,
            subDistrictId: e.target.value,
            subDistrictName: selectedSubDistrict?.name || "",
          }));
        }}
      >
        {subDistricts?.data?.map((j) => (
          <MenuItem
            key={j.id}
            value={j.id}
            sx={{
              bgcolor: search.subDistrictId === j.id ? "green" : "inherit",
              color: search.subDistrictId === j.id ? "white" : "inherit",
              "&:hover": {
                bgcolor: "#00D42A",
                color: "white",
              },
              "&:focus": {
                bgcolor: "#00D42A",
                color: "white",
              },
              paddingLeft: "30px",
              fontWeight: "700",
              py: "10px",
            }}
          >
            {j.name}
          </MenuItem>
        ))}
      </CustomTextField>

      <Button
        style={{ width: "100%" }}
        variant="contained"
        onClick={() => {
          setShowLocation(!showLocation);
        }}
      >
        SET LOCATION
      </Button>
    </Box>
  );
}

export default LocationFilter;
