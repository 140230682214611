import React from "react";
import { TextareaAutosize } from "@mui/base";
import { useTheme } from "@mui/system";

function CVRequestModalInput({ setMessage, message }) {
  const theme = useTheme();

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  return (
    <TextareaAutosize
      style={{
        boxSizing: "border-box",
        width: "100%",
        fontFamily: "IBM Plex Sans, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.5,
        padding: "8px 12px",
        borderRadius: 8,
        color: theme.palette.mode === "dark" ? grey[300] : grey[900],
        background: theme.palette.mode === "dark" ? grey[900] : "#fff",
        border: `1px solid ${
          theme.palette.mode === "dark" ? grey[700] : grey[200]
        }`,
        boxShadow: `0px 2px 2px ${
          theme.palette.mode === "dark" ? grey[900] : grey[50]
        }`,
      }}
      aria-label="minimum height"
      minRows={3}
      placeholder="Write a message"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
    />
  );
}

export default CVRequestModalInput;
