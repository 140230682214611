import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApi, selectApi } from "store/reducers/apiSlice";
import { UrlBuilder } from "helpers/UrlBuilder";
import portalImage from "../../../assets/images/portal/mockup.jpg";
import {
  Box,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import RequestedCvScreenDrwaer from "./RequestedCvScreenDrwaer";
import CvListSimpleBar from "./CvListSimpleBar";
import Dashboard from "../job/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles({
  nameContainer: {
    display: "flex",
    //alignItems: "center",
    width: "100%",
    padding: "20px",
    flex: 2,
    gap: "20px",
    marginBottom: "20px",
  },
  iconContainer: {
    borderRadius: "50px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "61px",
    height: "58px",
  },
  icon: {
    fontSize: "40px", // Adjust the icon size as needed
    color: "#6BD425", // Use the specified icon color
  },
  textContainer: {
    color: "#000000",
  },
  favoritesContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  favorites: {
    textAlign: "left",
  },
});

function RequestedCvScreen() {
  const dispatch = useDispatch();
  const {
    cv_requested,
    jobCategories,
    educationQualifications,
    jobTypes,
    singleResume,
  } = useSelector(selectApi);
  const classes = useStyles();
  const [selectCvIndex, setSelectCvIndex] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [workerId, setWorkerId] = useState(
    cv_requested?.data?.job_request[0]?.worker_user_id
  );

  const [limit, setLimit] = useState(8);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const fetchRequestedData = async () => {
      await Promise.all([
        dispatch(
          callApi({
            operationId: UrlBuilder.jobServiceApi(
              `job/cv_request/self?limit=${limit}`
            ),
            output: "cv_requested",
          })
        ),
      ]);
    };

    fetchRequestedData();
  }, [dispatch, limit]);

  useEffect(() => {
    if (cv_requested?.data?.job_request?.[0]?.worker_user_id) {
      setWorkerId(cv_requested?.data?.job_request[0]?.worker_user_id);
    }
  }, [cv_requested]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(
          callApi({
            operationId: UrlBuilder.jobServiceApi(`resume/${workerId}`),
            output: "singleResume",
          })
        ),
        dispatch(
          callApi({
            operationId: UrlBuilder.jobServiceApi(`job/categories`),
            output: "jobCategories",
          })
        ),
        dispatch(
          callApi({
            operationId: UrlBuilder.jobServiceApi(
              "job/education_qualifications"
            ),
            output: "educationQualifications",
          })
        ),
      ]);
    };

    fetchData();
  }, [dispatch, workerId, selectCvIndex]);

  const findCategoriesName = (id) => {
    let categories;
    jobCategories?.data.forEach((c) => {
      if (c.sub_categories) {
        c.sub_categories.forEach((sc) => {
          if (sc.id === id) {
            categories = sc;
          }
        });
        if (c.id === id) {
          categories = c;
        }
      } else if (c.id === id) {
        categories = c;
      }
    });
    return categories ? categories.name : "";
  };

  const findEducation = (id) => {
    let categories;
    educationQualifications?.data.forEach((c) => {
      if (c.sub_categories) {
        c.sub_categories.forEach((sc) => {
          if (sc.id == id) {
            categories = sc;
          }
        });
        if (c.id == id) {
          categories = c;
        }
      } else if (c.id == id) {
        categories = c;
      }
    });
    if (categories) {
      return categories.name.trim() === "No Academic Qualification"
        ? "Education: N/A"
        : categories.name;
    }
    return "";
  };
  const handleClick = (index, worker_user_id) => {
    setSelectCvIndex(index);
    setWorkerId(worker_user_id);
    setOpenDrawer(true);
  };

  const isBellow1030px = useMediaQuery("(max-width: 1330px)");

  //hide dashboard
  const boxRef = React.useRef(null);
  const [isBoxVisible, setIsBoxVisible] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsBoxVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [boxRef]);
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          position: "relative",
          width: "100%",
          //height: "70vh",
          display: "grid",
          // gap: "5px",
          gridTemplateColumns: {
            xs: "repeat(6, 1fr)", // 6 equal columns for extra-small screens
            md: isBellow1030px
              ? "repeat(6, 1fr)" // 6 equal columns for medium screens if condition is true
              : "repeat(3, 1fr) 1px repeat(2, 1fr)", // 6 equal columns for medium screens if condition is false
            lg: "repeat(3, 1fr) 1px repeat(2, 1fr)", // 3 flexible columns, 1 fixed-width column, 2 flexible columns for large screens
          },

          gridTemplateAreas: {
            xs: `
      "header header header header header header"
      "jobCards jobCards jobCards jobCards jobCards jobCards"

      `,
            md: isBellow1030px
              ? `
        "header header header header header header"
        "jobCards jobCards jobCards jobCardDetails jobCardDetails jobCardDetails"
        `
              : `
        "dashboard header header header header header"
        "dashboard jobCards jobCards . jobCardDetails jobCardDetails"
        "dashboard jobCards jobCards . jobCardDetails jobCardDetails"
        `,
            lg: `
      "dashboard header header  header header"
      "dashboard jobCards jobCards . jobCardDetails jobCardDetails"
      "dashboard jobCards jobCards . jobCardDetails jobCardDetails"
      `,
          },
        }}
      >
        {/* DASHBOARD */}

        {!isBellow1030px ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              gridArea: "dashboard",
              display: "flex",
              justifyContent: "center",
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <Dashboard />
          </Box>
        ) : (
          <>
            {/* Overlay */}
            {isBoxVisible && (
              <Box
                onClick={() => setIsBoxVisible(false)}
                sx={{
                  position: "fixed",
                  inset: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 5,
                }}
              />
            )}

            {/* DashBoard */}
            <Box
              ref={boxRef}
              sx={{
                bgcolor: "#FAFAFB",
                position: "fixed",
                zIndex: 6,
                top: -60,
                bottom: 0,
                left: 0, // Make sure it spans the full width
                width: "300px", // You might want to set a fixed width for consistency
                display: "flex",
                flexDirection: "column", // Ensure children stack vertically if needed
                justifyContent: "center", // Center children vertically
                alignItems: "center", // Center children horizontally
                transform: isBoxVisible ? "translateX(0)" : "translateX(-100%)",
                opacity: isBoxVisible ? 1 : 0,
                transition: "transform 0.4s ease, opacity 0.4s ease",
              }}
            >
              <Dashboard />
            </Box>
          </>
        )}

        <Box
          id="scrollableDiv"
          sx={{
            backgroundColor: "#F8FDFA",
            px: 3,
            pb: 3,
            height: "calc(100vh - 80px)",
            maxHeight: "100vh",
            gridArea: "jobCards",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "auto", // Ensure this remains to enable scrolling
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "sticky", // Sticky positioning for the header
              top: 0,
              zIndex: 3,
              backgroundColor: "#F8FDFA", // Match the background color
              //boxShadow: "0px 4px 2px -2px rgba(0,0,0,0.1)", // Optional: Add shadow to distinguish it
            }}
          >
            <Typography
              variant="h2"
              fontSize={isMobile ? "1.625rem" : "1.75rem"}
              sx={{
                pt: "0.625rem",
                pl: 4.8,
              }}
              color="primary.main"
            >
              All Request List
            </Typography>
          </Box>

          <CvListSimpleBar
            limit={limit}
            setLimit={setLimit}
            data2={cv_requested}
            findCategoriesName={findCategoriesName}
            findEducation={findEducation}
            handleClick={handleClick}
          />
        </Box>

        <Box
          sx={{
            gridArea: "jobCardDetails",
          }}
        >
          <RequestedCvScreenDrwaer
            isMobile={isMobile}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            singleResume={singleResume}
            portalImage={portalImage}
            findCategoriesName={findCategoriesName}
            classes={classes}
            jobTypes={jobTypes}
            educationQualifications={educationQualifications}
          />
        </Box>

        {isBellow1030px && (
          <IconButton
            sx={{
              position: "absolute",
              top: 6,
              left: 2,
              cursor: "pointer",
              zIndex: 3,
            }}
            onClick={() => setIsBoxVisible(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>
    </Container>
  );
}

export default RequestedCvScreen;
