import { Box, Typography } from "@mui/material";

export const NameSplitting = ({ fullName = "" }) => {
  const name = fullName.split(" ");
  const firstName = name[0];
  const lastName = name.slice(1).join(" ");

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Typography variant="h4" style={{ fontSize: 27 }}>
        {firstName}
      </Typography>
      <Typography
        variant="h4"
        style={{
          fontSize: 27,
          filter: "blur(5px) brightness(20%)",
          pointerEvents: "none",
          userSelect: "none",
        }}
      >
        {lastName}
      </Typography>
    </Box>
  );
};
