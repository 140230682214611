import React, {lazy} from 'react';
import { styled, Container } from '@mui/material';
import Hero from 'sections/portal/Hero';
import PrivacyPolicy from 'pages/company/privacy/privacy_policy';
import Footer from "../../sections/portal/Footer";
import Header from "../../layout/CommonLayout/Header";
// const Header = lazy(() => import('src/layout/CommonLayout/index'));
const BlurredDiv = styled('div')({
    position: 'absolute',
    width: 240,
    height: 240,
    background: '#AFFC41',
    opacity: 0.8,
    filter: 'blur(207.5px)',
});

const Company = () => {
    return (
        <div>
            <Header layout={'blank'} />
            <BlurredDiv style={{ left: 12, top: -32 }} />
            <BlurredDiv style={{ right: 12, top: 600 }} />
            <Container maxWidth="xl" sx={{ mb: 8 }}>
                <PrivacyPolicy/>
                <Footer />
            </Container>
        </div>
    );
};

export default Company;
