import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Drawer,
  Stack,
  useMediaQuery,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import ApplyModal from "./ApplyModal";
import JobDescription from "./JobDescription";
import JobRequirement from "./JobRequirement";
import { getUserRole } from "helpers/AuthUtils";
import { useNavigate } from "react-router";
import Lock from "../../../assets/lock.gif";
import { useDispatch, useSelector } from "react-redux";
import { UrlBuilder } from "helpers/UrlBuilder";
import { callApi, selectApi } from "store/reducers/apiSlice";
const DetailsMenu = ({ moreCvDetails, setMoreCvDetails }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button
        onClick={() => setMoreCvDetails("Description")}
        sx={
          moreCvDetails === "Description"
            ? { fontStyle: "bolt", borderBottom: 2, borderRadius: 0 }
            : { color: "#A7A7A7" }
        }
      >
        Description
      </Button>
      <Button
        onClick={() => setMoreCvDetails("Requirement")}
        sx={
          moreCvDetails === "Requirement"
            ? { fontStyle: "bolt", borderBottom: 2, borderRadius: 0 }
            : { color: "#A7A7A7" }
        }
      >
        Requirement
      </Button>
    </Stack>
  );
};
const JobPostsDisplay = ({
  setRefetch,
  refetch,
  isMobile,
  openDrawer,
  setOpenDrawer,
  allJobPostsList,
  classes,
  portalImage,
  isLoggedIn,
  selectJobIndex,
  isSaved,
  isApplied,
  handleUnsaveJob,
  handleSaveJob,
  handleModal,
  message,
  setMessage,
  handleApplyJob,
  open,
  handleClose,
  moreCvDetails,
  setMoreCvDetails,
  useStyles,
}) => {
  const role = getUserRole();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appliedJobById } = useSelector(selectApi);

  useEffect(() => {
    if (role === 1) {
      dispatch(
        callApi({
          operationId: UrlBuilder.jobServiceApi(
            `job/posts/applications?job_post_id=${allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post?.id}`
          ),
          output: "appliedJobById",
        })
      );
    }
  }, [refetch]);

  const jobPost =
    isLoggedIn && (role === 1 || role === 5)
      ? allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
      : allJobPostsList?.data?.public_job_posts[selectJobIndex];

  const category =
    isLoggedIn && (role === 1 || role === 5)
      ? jobPost?.categories?.name
      : allJobPostsList?.data?.public_job_posts[selectJobIndex]?.category;

  const renderContent = () => (
    <>
      {allJobPostsList?.data?.total === 0 ? (
        <Grid item md={6} sm={12} sx={{ padding: 5 }}></Grid>
      ) : (
        <Grid
          item
          md={6}
          sm={12}
          sx={{
            position: "relative",
            padding: 5,
            overflowY: role !== 1 && role !== 5 ? "clip" : "auto",
            pr: 1.2,
            height: openDrawer ? "100%" : "calc(100vh - 80px)",
          }}
        >
          {!isLoggedIn || (role !== 1 && role !== 5) ? (
            <Box
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              <Box component={"img"} src={Lock} />
              <Typography
                mb={1}
                variant="h4"
                component="h2"
                sx={{ whiteSpace: "nowrap", textWrap: "nowrap", mt: "5px" }}
              >
                Please Login to unlock
              </Typography>
            </Box>
          ) : null}
          <Box
            sx={{
              position: "sticky",
              zIndex: 10,
              top: 0,
              backgroundColor: "#FFFF",
            }}
          >
            <Box className={classes.nameContainer}>
              <Box className={classes.iconContainer}>
                {portalImage ? (
                  <Box
                    component={"img"}
                    src={portalImage}
                    alt="Preview"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <HomeIcon fontSize="large" style={{ fontSize: "50px" }} />
                )}
              </Box>
              <Box className={classes.textContainer} ml={2}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: isMobile ? "1.25rem" : "1.875rem",
                    color: "#010A03",
                  }}
                >
                  {jobPost?.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "secondary.main",
                    fontSize: isMobile ? "0.875rem" : "1.25rem",
                  }}
                >
                  {category}
                </Typography>
                {isLoggedIn && role === 1 ? (
                  <Box className={classes.favoritesContainer}>
                    {isSaved ? (
                      <Button
                        style={{ width: "fit-content", margin: 5 }}
                        variant="contained"
                        onClick={() => {
                          handleUnsaveJob();
                          setRefetch(true);
                          setRefetch(false);
                        }}
                      >
                        Unsave
                      </Button>
                    ) : (
                      <Button
                        style={{ width: "fit-content", margin: 5 }}
                        variant="contained"
                        onClick={() => {
                          handleSaveJob();
                          setRefetch(true);
                          setRefetch(false);
                        }}
                      >
                        Save Job
                      </Button>
                    )}
                    {appliedJobById?.data?.job_application_details?.length >
                    0 ? (
                      <Button
                        style={{ width: "fit-content", margin: 5 }}
                        variant="contained"
                      >
                        Applied
                      </Button>
                    ) : (
                      <Button
                        style={{ width: "fit-content", margin: 5 }}
                        variant="contained"
                        onClick={() => handleModal()}
                      >
                        Apply Job
                      </Button>
                    )}
                    {isLoggedIn && (role === 1 || role === 5) ? (
                      <ApplyModal
                        message={message}
                        setMessage={setMessage}
                        selectJobIndex={selectJobIndex}
                        allJobPostsList={allJobPostsList}
                        handleApplyJob={handleApplyJob}
                        open={open}
                        handleClose={handleClose}
                      />
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </Box>

            <DetailsMenu
              setMoreCvDetails={setMoreCvDetails}
              moreCvDetails={moreCvDetails}
            />
          </Box>
          <Grid
            container
            spacing={0}
            mt={2}
            sx={{
              filter:
                (isLoggedIn && role === 1) || (isLoggedIn && role === 5)
                  ? "none"
                  : "blur(5px)",
            }}
          >
            <Grid item xs={12}>
              <SimpleBar>
                {moreCvDetails === "Description" && (
                  <JobDescription
                    allJobPostsList={allJobPostsList}
                    selectJobIndex={selectJobIndex}
                    useStyles={useStyles}
                  />
                )}
                {moreCvDetails === "Requirement" && (
                  <JobRequirement
                    allJobPostsList={allJobPostsList}
                    selectJobIndex={selectJobIndex}
                    useStyles={useStyles}
                  />
                )}
              </SimpleBar>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          open={openDrawer}
          anchor="right"
          onClose={() => setOpenDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "80%",
            },
          }}
        >
          {renderContent()}
        </Drawer>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default JobPostsDisplay;
