import React from "react"
import SimpleBar from "components/third-party/SimpleBar"
import { Box, Grid, Typography } from "@mui/material"
import JobCard from "./JobCard"
import { useSelector } from "react-redux"
import { selectApi } from "store/reducers/apiSlice"
import InfiniteScroll from "react-infinite-scroll-component"
import useAuth from "hooks/useAuth"
import Shimmer from "./Shimmer"
import { getUserRole } from "helpers/AuthUtils"

function JobListSimpleBar({
  data,
  search,
  setSearch,
  setSearchResults,
  handleCardClick,
  selectJobIndex,
}) {
  const { allJobPostsList } = useSelector(selectApi)
  const isLoggedIn = useAuth()
  const role = getUserRole()
  const hasMoreData = () => {
    if (isLoggedIn && (role === 1 || role === 5)) {
      return data?.total > allJobPostsList?.data?.job_posts?.length
    }
    return data?.total > allJobPostsList?.data?.public_job_posts?.length
  }
  return (
    <SimpleBar>
      {data?.total === 0 ? (
        <Box sx={{ alignItems: "center", textAlign: "center", margin: 30 }}>
          <Typography variant="h2" fontSize={30} color="primary.main">
            No Data
          </Typography>
        </Box>
      ) : (
        <Grid>
          <InfiniteScroll
            dataLength={
              (role === 1 || role === 5) && isLoggedIn
                ? allJobPostsList?.data?.job_posts?.length
                : allJobPostsList?.data?.public_job_posts?.length
            } //This is important field to render the next data
            next={() => {
              setSearch((prev) => ({ ...prev, limit: search.limit + 20 }))
              setSearchResults(true)
            }}
            hasMore={hasMoreData()}
            loader={<Shimmer />}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget="scrollableDiv" // Add this line
            // below props only if you need pull down functionality
          >
            {isLoggedIn && (role === 1 || role === 5) ? (
              <>
                {data?.job_posts?.map((item, index) => (
                  <Box
                    key={`EmployeeCard - ${index}`}
                    // xs={12}
                    // sm={6}
                    // md={4}
                    // m={2}
                    // lg={3}
                    my={2}
                    onClick={() => handleCardClick(index)}
                    sx={{
                      border:
                        selectJobIndex === index ? "2px solid #39FF14" : "none",
                      borderRadius: "8px",
                    }}
                  >
                    <JobCard
                      heading={item?.job_post.title}
                      subheading={item?.job_post.categories.name}
                      data={item}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <>
                {data?.public_job_posts?.map((item, index) => (
                  <Box
                    key={`EmployeeCard - ${index}`}
                    my={2}
                    onClick={() => handleCardClick(index)}
                    sx={{
                      border:
                        selectJobIndex === index ? "2px solid #39FF14" : "none",
                      borderRadius: "8px",
                    }}
                  >
                    <JobCard
                      heading={item?.title}
                      subheading={item?.categories}
                      data={item}
                    />
                  </Box>
                ))}
              </>
            )}
          </InfiniteScroll>
        </Grid>
      )}
    </SimpleBar>
  )
}

export default JobListSimpleBar
