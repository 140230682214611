import { Box, Skeleton } from "@mui/material";
import React from "react";

function Shimmer() {
  return (
    <>
      <Box
        sx={{
          padding: 1,
          bgcolor: "#ECF2F7",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
            <Skeleton width="40%" />
            <Skeleton width="30%" />
            <Skeleton width="70%" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: 1, bgcolor: "#ECF2F7", borderRadius: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
            <Skeleton width="40%" />
            <Skeleton width="30%" />
            <Skeleton width="70%" />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Shimmer;
