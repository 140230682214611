import { lazy } from "react"

// project import
import Loadable from "components/Loadable"
import CreateJobForm from "pages/admin/CreateJobForm"

const MainLayout = Loadable(lazy(() => import("layout/MainLayout")))
const AuthGuard = Loadable(lazy(() => import("utils/route-guard/AuthGuard")))
const Dashboard = Loadable(
  lazy(() => import("pages/employer/dashboard/Dashboard"))
)
const Jobs = Loadable(lazy(() => import("pages/employer/job/Jobs")))
const CreateJob = Loadable(lazy(() => import("pages/employer/job/CreateJob")))
const JobDetails = Loadable(
  lazy(() => import("pages/common/job-details/JobDetails"))
)
const ChatComponent = Loadable(lazy(() => import("pages/employer/chat/Chat")))
const RequestedCVComponent = Loadable(
  lazy(() => import("pages/employer/dashboard/RequestedCvScreen"))
)
const RequestedCvAcceptedComponent = Loadable(
  lazy(() => import("pages/employer/dashboard/RequestedCvAccepted"))
)
const NotficationComponent = Loadable(
  lazy(() => import("pages/employer/job/NotificationListEmp"))
)
const UserProfileComponent = Loadable(
  lazy(() => import("components/profile/UserProfile"))
)

const EmployerRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "jobs",
          element: <Jobs />,
        },
        {
          path: "cv-requests",
          element: <RequestedCVComponent />,
        },
        {
          path: "request-acepted",
          element: <RequestedCvAcceptedComponent />,
        },
        {
          path: "post-new-job",
          element: <CreateJobForm />,
        },
        {
          path: "post-new-job-old",
          element: <CreateJob />,
        },
        {
          path: "jobs/job-details/:id",
          element: <JobDetails />,
        },
        {
          path: "chat",
          element: <ChatComponent />,
        },
        {
          path: "notfications",
          element: <NotficationComponent />,
        },
        {
          path: "profile",
          element: <UserProfileComponent />,
        },
      ],
    },
  ],
}

export default EmployerRoutes
