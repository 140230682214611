import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainCard from "components/cards/MainCard";
import FilterCvRequests from "../category/FilterCategory";
import { callApi, selectApi } from "store/reducers/apiSlice";
import { UrlBuilder } from "helpers/UrlBuilder";
import DataGridTableCustom from "components/DataGrid";
import { Dialog, DialogContent, IconButton, Stack } from "@mui/material";
import { LinkOff, Upload, Visibility } from "@mui/icons-material";
import ImageUploadComponent from "./ImageUploadComponent";
import UpdateImageUrl from "./UpdateImageUrl";

function Category() {
  const dispatch = useDispatch();
  const { categories } = useSelector(selectApi);
  const [page, setPage] = useState(1);
  const [id, setId] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // State for showing the dialog
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, [isDialogOpen]);

  function fetchData() {
    dispatch(
      callApi({
        operationId: UrlBuilder.jobServiceApi("job/categories"),
        output: "categories",
      })
    );
  }


  // Function to open the dialog
  const handleOpenDialog = (id) => {
    setId(id)
    setDialogOpen(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const onPageinationModelChange = (params) => {
    setPage(params.page + 1);
    setPageSize(params.pageSize);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "name_bn",
      headerName: "Bangla Name",
      flex: 1,
    },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`Image ${params.row.name}`}
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      align: "center",
      renderCell: (params) => (
        <Stack direction="row">
          <IconButton onClick={() => handleOpenDialog(params.row.id)}>
            <LinkOff />
          </IconButton>
          {/* Add more IconButton components or actions as needed */}
        </Stack>
      ),
    },

    // Add more columns as needed
  ];

  return (
    <MainCard title="List of Categories">
      <FilterCvRequests page={page} pageSize={pageSize} />
      <DataGridTableCustom
        sx={{ mt: 2 }}
        rows={categories?.data || []}
        columns={columns}
        onPageChange={onPageinationModelChange}
        paginationMode="client"
        pageSize={pageSize}
        rowCount={categories?.data?.length || 0}
        rowHeight={150}
      />
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogContent>
          {/* <UpdateImageUrl id={id} /> */}
          <ImageUploadComponent  id={id} handleCloseDialog={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </MainCard>
  );
}

export default Category;
