import React from 'react';
import {Grid, Typography, Link, useTheme, Paper, Container} from '@mui/material';


const PrivacyPolicy = () => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Paper>
            <Container>
                <Paper sx={{padding: 2, my: 10,}}>
                    <Typography variant="h3" gutterBottom>
                        1. Information We Collect
                    </Typography>
                    <Typography variant="h4" paragraph>
                        1.1. Personal Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <span style={{fontWeight: "bold"}}> Full Name:</span> We collect your full name to personalize
                        your experience on Gorillajobs.
                        <br/>
                        <span style={{fontWeight: "bold"}}> Date of Birth:</span> Your date of birth is
                        collected to verify your age and provide age-appropriate
                        services.
                        <br/>
                        <span style={{fontWeight: "bold"}}>Location: </span> We collect your location information to
                        connect you with relevant opportunities based
                        on your geographical preferences.
                        <br/>
                        <span style={{fontWeight: "bold"}}>Username/Phone Number:</span> Your username is your phone
                        number, serving as a unique identifier and
                        contact method.
                        <br/>
                        <span style={{fontWeight: "bold"}}>Password:</span> We collect a password to secure your account
                        and protect your personal information.
                    </Typography>

                    <Typography variant="h4" paragraph>
                        1.2. Employer Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        For employers, we may collect additional information, such as the name of the company, industry,
                        and job details for job listings.
                    </Typography>

                    <Typography variant="h4" paragraph>
                        1.3. Employee Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        For employees, we may collect additional information, such as their educational background,
                        employment history, certification history, etc. </Typography>

                    <Typography variant="h4" paragraph>
                        1.4. Usage Data
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We may also collect information on how the service is accessed and used ("Usage Data"). This may
                        include the type of device you use, your device's Internet Protocol address (IP address),
                        browser type, and version, the pages of our service that you visit, the time and date of your
                        visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
                    </Typography>

                    <Typography variant="h4" paragraph>
                        2. How We Use Your Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We use the collected data for various purposes, including:
                        <br/>
                        <span style={{fontWeight: "bold"}}> Providing and maintaining the service:</span> We
                        use your personal information for the effective
                        operation of Gorillajobs.
                        <br/>
                        <span style={{fontWeight: "bold"}}>Personalizing your experience:</span> We may use your data to
                        provide personalized content and
                        recommendations.
                        <br/>
                        <span style={{fontWeight: "bold"}}>Communication: </span> Your contact details may be used for
                        important updates and notifications related
                        to the service.
                        <br/>

                        <span style={{fontWeight: "bold"}}> Improving the service:</span> We analyze usage data to enhance and optimize our app for a better user
                        experience.
                    </Typography>
                    <Typography variant="h4" paragraph>
                        3. Information Sharing and Disclosure
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We do not share your personal information with third parties unless required by law or with your
                        explicit consent. If you are an employer, we may share relevant information with potential
                        employees or vice versa, based on the nature of our service.
                    </Typography>
                    <Typography variant="h4" paragraph>
                        4. Security
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We prioritize the security of your data and take measures to protect it from unauthorized access
                        or disclosure. We use industry-standard security measures to safeguard your information.
                    </Typography>
                    <Typography variant="h4" paragraph>
                        5. Your Choices
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You have the right to review and update your personal information or opt-out of certain
                        communications. You can manage your preferences in the app settings. </Typography>
                    <Typography variant="h4" paragraph>
                        6. Changes to This Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We may update our privacy policy from time to time. Any changes will be effective upon posting
                        the revised policy on this page. We will notify you of any significant changes through the app
                        or other communication channels.
                    </Typography>
                    <Typography variant="h4" paragraph>
                        7. Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions or concerns about this privacy policy, please contact us at gorillajobs@gorillamove.com.
                    </Typography>
                </Paper>
            </Container>
        </Paper>
    );
};

export default PrivacyPolicy;
