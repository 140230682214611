import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import { element } from "prop-types";

const MainLayout = Loadable(lazy(() => import("layout/MainLayout")));
const AuthGuard = Loadable(lazy(() => import("utils/route-guard/AuthGuard")));
const Dashboard = Loadable(
  lazy(() => import("pages/worker/dashboard/Dashboard"))
);
const Jobs = Loadable(lazy(() => import("pages/worker/job/Jobs")));
// const CreateJob = Loadable(lazy(() => import("pages/worker/job/CreateJob")));
const JobDetails = Loadable(
  lazy(() => import("pages/common/job-details/JobDetails"))
);
const ChatComponent = Loadable(lazy(() => import("pages/worker/chat/Chat")));
const NotficationComponent = Loadable(
  lazy(() => import("pages/worker/job/NotificationList"))
);
const ContactRequestComponent = Loadable(
  lazy(() => import("pages/worker/job/ContactRequest"))
);
const Profile = Loadable(
  lazy(() => import("pages/worker/Profile/WorkerProfile"))
);
const PersonalInfoUpdate = Loadable(
  lazy(() => import("pages/worker/Profile/PersonalInfoUpdate"))
);

const WorkerRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "jobs",
          element: <Jobs />,
        },
        {
          path: "notfications",
          element: <NotficationComponent />,
        },
        // {
        //   path: "post-new-job",
        //   element: <CreateJob />,
        // },
        {
          path: "jobs/job-details/:id",
          element: <JobDetails />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "profile/update-personal-info",
          element: <PersonalInfoUpdate />,
        },
        {
          path: "chat",
          element: <ChatComponent />,
        },
        {
          path: "contactrequest",
          element: <ContactRequestComponent />,
        },
      ],
    },
  ],
};

export default WorkerRoutes;
