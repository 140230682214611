import { Box, Typography } from "@mui/material";
import { getUserRole } from "helpers/AuthUtils";
import useAuth from "hooks/useAuth";
import React from "react";

function JobRequirement({ allJobPostsList, selectJobIndex, useStyles }) {
  const isLoggedIn = useAuth();
  const role = getUserRole();

  return (
    <Box style={{ pl: 1, pr: 3 }}>
      {isLoggedIn && (role === 1 || role === 5) ? (
        <Box ml={2}>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Employment type :{" "}
            {
              allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                .job_type.name
            }{" "}
            {allJobPostsList?.data?.job_posts[selectJobIndex]?.work_place
              ? `( ${allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post.work_place.name} )`
              : ""}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Job level :{" "}
            {
              allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                ?.job_level?.name
            }
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Experience :{" "}
            {
              allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                .job_exp_min
            }{" "}
            Years
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Min Education :{" "}
            {
              allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                .edu_qualification.name
            }
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Age :{" "}
            {allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post.age_min}{" "}
            -{" "}
            {allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post.age_max}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Salary :{" "}
            {
              allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                .salary_min
            }{" "}
            -{" "}
            {
              allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post
                .salary_max
            }{" "}
            {`( ${allJobPostsList?.data?.job_posts[selectJobIndex]?.job_post.job_pay_type.name})`}
          </Typography>
        </Box>
      ) : (
        <Box ml={2}>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Employment type :{" "}
            {allJobPostsList?.data?.public_job_posts[selectJobIndex]?.job_type}{" "}
            {allJobPostsList?.data?.public_job_posts[selectJobIndex]?.work_place
              ? `( ${allJobPostsList?.data?.public_job_posts[selectJobIndex]?.work_place} )`
              : ""}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Job level :{" "}
            {allJobPostsList?.data?.public_job_posts[selectJobIndex]?.job_level}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Experience :{" "}
            {
              allJobPostsList?.data?.public_job_posts[selectJobIndex]
                ?.job_exp_min
            }{" "}
            Years
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Min Education :{" "}
            {
              allJobPostsList?.data?.public_job_posts[selectJobIndex]
                ?.edu_qualification
            }
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Age :{" "}
            {allJobPostsList?.data?.public_job_posts[selectJobIndex]?.age_min} -{" "}
            {allJobPostsList?.data?.public_job_posts[selectJobIndex]?.age_max}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "secondary.main", fontSize: 15 }}
          >
            Salary :{" "}
            {
              allJobPostsList?.data?.public_job_posts[selectJobIndex]
                ?.salary_min
            }{" "}
            -{" "}
            {
              allJobPostsList?.data?.public_job_posts[selectJobIndex]
                ?.salary_max
            }{" "}
            {`( ${allJobPostsList?.data?.public_job_posts[selectJobIndex]?.pay_type})`}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default JobRequirement;
