import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router";
import HomePageTitleLogo from "../../assets/homePageLogo.png";
import GorillaLogo from "../../assets/gorillajobsLogo.png";
import leftCardImages from "../../assets/leftCardImages.png";
import rightCardImages from "../../assets/rightCardImages.png";

const ImageCard = ({ increase, borderColor, heading }) => {
  const imageCardSrc = increase ? leftCardImages : rightCardImages;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // position: "relative",
        border: `10px solid ${borderColor}`,
        borderRadius: "60px",
        // paddingTop: { xs: "20px", md: "23px" },
        // paddingRight: { xs: "8px", md: "10px" },
        // paddingLeft: { xs: "35px", md: "42px" },
        // paddingBottom: { xs: "8px", md: "10px" },
        padding: "23px 10px 42px 10px",
        transition: "border-color 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth transitions for border color and scale
        // transform: isHovered ? "scale(1.1)" : "scale(1)", // Scale transformation on hover
      }}
    >
      {/* Card Heading */}
      <Typography
        variant="h2"
        sx={{
          fontWeight: 800,
          fontSize: { xs: 25, sm: 30, md: 35, lg: 40 },
          color: "#000000",
          textTransform: "uppercase",
          marginTop: {
            xs: "8px",
            sm: "15px",
            md: "20px",
            lg: "23px",
          },
          position: "relative",
          zIndex: 2, // Ensure text is above the image
        }}
      >
        {heading}
      </Typography>
      {/* Card Image */}
      <Box
        sx={{
          display: "flex",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box
          component="img"
          src={imageCardSrc}
          sx={{
            width: {
              xs: "330px",
              sm: "380px",
              md: "400px",
              lg: "500px",
              xl: "600px",
            },
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

const MainPage = () => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 80px)", // Adjusted height for navbar
        // height: "100vh",
        bgcolor: "#F4FCF7",
        backgroundColor: "#fffff",
        mb: "70px",
      }}
    >
      {/* Titlte Logo */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: { xs: "45px", md: "56px" },
          mt: { xs: "100px", md: "70px", lg: "0px" },
        }}
      >
        <Box
          component="img"
          src={HomePageTitleLogo}
          sx={{ width: { xs: "280px", sm: "320", md: "400px" } }}
        />
      </Box>

      <Box
        flexDirection={{ xs: "column", md: "row" }}
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: { xs: "95px", md: "50px" },
          position: "relative",
        }}
      >
        {/* Left Image Card */}
        <Button
          onClick={() => {
            navigate("/search-cv");
          }}
          sx={{
            transition:
              "border-color 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth transitions for border color and scale
            "&:hover": {
              backgroundColor: "transparent", // Disable hover background color change
              boxShadow: "none", // Disable hover box shadow
              transform: "scale(1.1)",
            },
          }}
        >
          <ImageCard
            increase
            borderColor={"#BCFDB3"}
            heading={"Hire Talents"}
          />
        </Button>
        {/* Right Image Card */}
        <Button
          onClick={() => {
            navigate("/search-job");
          }}
          sx={{
            transition:
              "border-color 0.3s ease-in-out, transform 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
              transform: "scale(1.1)",
            },
          }}
        >
          <ImageCard
            increase={false}
            borderColor={"#CCC7DB"}
            heading={"Browse Jobs"}
          />
        </Button>
        {/* Green Gorilla Image */}
        <Box
          sx={{
            zIndex: 1,
            borderRadius: "50%",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -50%)",
            top: { xs: "50%", sm: "50%", md: "57%" },
          }}
        >
          <Box
            component="img"
            src={GorillaLogo}
            alt="Green Gorilla"
            sx={{
              width: { xs: "150px", md: "180px" },
              height: { xs: "150px", md: "180px" },
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default MainPage;
