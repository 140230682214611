// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.1602 17.8312C30.2586 16.7328 30.2586 14.9519 29.1602 13.8535C28.0617 12.7551 26.2809 12.7551 25.1825 13.8535C24.084 14.9519 24.084 16.7328 25.1825 17.8312C26.2809 18.9296 28.0617 18.9296 29.1602 17.8312Z" fill="#019961"/>
<path d="M29.9303 15.0525C29.926 15.6098 29.7564 16.1533 29.443 16.6142C29.1296 17.075 28.6866 17.4326 28.1699 17.6416C27.6533 17.8506 27.0862 17.9015 26.5406 17.7881C25.9949 17.6747 25.4951 17.402 25.1045 17.0044C24.714 16.6068 24.4501 16.1023 24.3463 15.5548C24.2426 15.0072 24.3037 14.4411 24.5217 13.9282C24.7398 13.4153 25.1052 12.9787 25.5715 12.6735C26.0379 12.3684 26.5843 12.2084 27.1416 12.2139C27.8874 12.2218 28.5997 12.525 29.1224 13.057C29.6451 13.5891 29.9356 14.3066 29.9303 15.0525Z" fill="#019A60"/>
<path d="M29.8187 14.2578C29.8143 14.816 29.6444 15.3604 29.3304 15.822C29.0164 16.2836 28.5725 16.6417 28.0548 16.8509C27.5372 17.06 26.9692 17.1109 26.4226 16.9969C25.8761 16.883 25.3757 16.6095 24.9847 16.211C24.5937 15.8125 24.3298 15.3069 24.2263 14.7583C24.1229 14.2097 24.1846 13.6427 24.4036 13.1292C24.6226 12.6156 24.9891 12.1787 25.4566 11.8735C25.9241 11.5684 26.4717 11.4089 27.0299 11.4152C27.7764 11.4231 28.4893 11.7268 29.0121 12.2597C29.535 12.7926 29.825 13.5112 29.8187 14.2578Z" fill="#019B5F"/>
<path d="M29.655 13.4809C29.6507 14.0396 29.4807 14.5845 29.1666 15.0466C28.8526 15.5087 28.4085 15.8673 27.8906 16.077C27.3727 16.2867 26.8043 16.3381 26.2572 16.2247C25.7101 16.1113 25.2089 15.8382 24.817 15.4399C24.4252 15.0416 24.1602 14.5361 24.0557 13.9872C23.9512 13.4383 24.0118 12.8708 24.2299 12.3564C24.448 11.842 24.8137 11.4038 25.2809 11.0972C25.748 10.7907 26.2956 10.6296 26.8543 10.6343C27.6028 10.6417 28.3178 10.9454 28.8428 11.479C29.3677 12.0125 29.6598 12.7324 29.655 13.4809Z" fill="#019C5E"/>
<path d="M26.6108 15.5309C28.173 15.5309 29.4394 14.2645 29.4394 12.7023C29.4394 11.1401 28.173 9.87367 26.6108 9.87367C25.0486 9.87367 23.7822 11.1401 23.7822 12.7023C23.7822 14.2645 25.0486 15.5309 26.6108 15.5309Z" fill="#019D5D"/>
<path d="M28.3435 13.9728C29.4497 12.8666 29.4497 11.0731 28.3435 9.96693C27.2373 8.86074 25.4438 8.86073 24.3376 9.96692C23.2315 11.0731 23.2315 12.8666 24.3377 13.9728C25.4439 15.079 27.2373 15.079 28.3435 13.9728Z" fill="#019E5C"/>
<path d="M28.8603 11.2818C28.8555 11.8428 28.6845 12.3898 28.3689 12.8536C28.0533 13.3174 27.6073 13.6772 27.0872 13.8875C26.5671 14.0979 25.9963 14.1492 25.447 14.0351C24.8977 13.921 24.3946 13.6466 24.0012 13.2466C23.6079 12.8466 23.342 12.3389 23.2372 11.7878C23.1324 11.2366 23.1934 10.6668 23.4124 10.1503C23.6314 9.63384 23.9987 9.19391 24.4678 8.88616C24.9368 8.5784 25.4866 8.41665 26.0476 8.42136C26.4202 8.42422 26.7886 8.50051 27.1316 8.64586C27.4747 8.79122 27.7857 9.00278 28.047 9.26845C28.3082 9.53413 28.5145 9.84871 28.6541 10.1942C28.7936 10.5397 28.8637 10.9092 28.8603 11.2818Z" fill="#019F5B"/>
<path d="M27.6528 12.6257C28.7712 11.5255 28.7859 9.72704 27.6858 8.60868C26.5856 7.49031 24.7871 7.47556 23.6688 8.57573C22.5504 9.67589 22.5357 11.4744 23.6358 12.5927C24.736 13.7111 26.5345 13.7258 27.6528 12.6257Z" fill="#02A05A"/>
<path d="M27.2646 11.9309C28.3755 10.82 28.3755 9.01897 27.2646 7.90812C26.1538 6.79726 24.3527 6.79724 23.2419 7.9081C22.131 9.01896 22.131 10.82 23.2419 11.9309C24.3527 13.0417 26.1538 13.0417 27.2646 11.9309Z" fill="#02A159"/>
<path d="M27.5278 10.102C27.9807 8.59537 27.1265 7.00692 25.6199 6.55406C24.1133 6.1012 22.5248 6.95541 22.0719 8.46201C21.6191 9.96861 22.4733 11.5571 23.9799 12.0099C25.4865 12.4628 27.075 11.6086 27.5278 10.102Z" fill="#02A259"/>
<path d="M24.3166 11.5548C25.892 11.5548 27.1691 10.2777 27.1691 8.70227C27.1691 7.12688 25.892 5.84977 24.3166 5.84977C22.7412 5.84977 21.4641 7.12688 21.4641 8.70227C21.4641 10.2777 22.7412 11.5548 24.3166 11.5548Z" fill="#02A358"/>
<path d="M26.649 8.16645C26.6446 8.73186 26.4727 9.28329 26.1549 9.75097C25.8371 10.2187 25.3878 10.5816 24.8637 10.7938C24.3396 11.0061 23.7644 11.0582 23.2107 10.9435C22.657 10.8288 22.1498 10.5524 21.7532 10.1494C21.3566 9.7464 21.0885 9.23483 20.9826 8.67939C20.8768 8.12395 20.9381 7.54959 21.1587 7.02899C21.3794 6.50839 21.7495 6.06493 22.2222 5.75469C22.6949 5.44444 23.249 5.28136 23.8144 5.28607C24.1897 5.28893 24.5608 5.36573 24.9064 5.51206C25.252 5.65839 25.5653 5.87138 25.8286 6.13888C26.0918 6.40638 26.2998 6.72313 26.4405 7.07103C26.5813 7.41893 26.6521 7.79116 26.649 8.16645Z" fill="#02A457"/>
<path d="M25.8262 8.86803C26.5008 7.4383 25.8887 5.7324 24.459 5.0578C23.0292 4.38319 21.3233 4.99533 20.6487 6.42506C19.9741 7.85479 20.5863 9.56069 22.016 10.2353C23.4457 10.9099 25.1516 10.2978 25.8262 8.86803Z" fill="#02A556"/>
<path d="M25.494 7.4452C25.6545 5.87027 24.5079 4.46339 22.933 4.30286C21.3581 4.14233 19.9512 5.28893 19.7907 6.86386C19.6301 8.43879 20.7767 9.84567 22.3517 10.0062C23.9266 10.1667 25.3335 9.02013 25.494 7.4452Z" fill="#02A655"/>
<path d="M24.5456 8.00642C25.2773 6.60013 24.7305 4.86689 23.3242 4.13513C21.9179 3.40337 20.1847 3.95019 19.4529 5.35649C18.7211 6.76279 19.268 8.49604 20.6743 9.2278C22.0806 9.95956 23.8138 9.41272 24.5456 8.00642Z" fill="#02A754"/>
<path d="M23.3764 8.32433C24.4989 7.20181 24.4989 5.38185 23.3764 4.25933C22.2538 3.1368 20.4339 3.13678 19.3113 4.25931C18.1888 5.38183 18.1888 7.20183 19.3113 8.32435C20.4339 9.44688 22.2538 9.44686 23.3764 8.32433Z" fill="#02A853"/>
<path d="M22.956 7.62806C23.9101 6.35647 23.6527 4.55222 22.3811 3.59818C21.1095 2.64414 19.3053 2.90158 18.3512 4.17317C17.3972 5.44475 17.6546 7.24897 18.9262 8.20301C20.1978 9.15705 22.002 8.89964 22.956 7.62806Z" fill="#02A952"/>
<path d="M21.9826 7.65972C23.1083 6.53408 23.1083 4.70907 21.9826 3.58343C20.857 2.45779 19.0319 2.45777 17.9063 3.58341C16.7807 4.70905 16.7807 6.53408 17.9063 7.65972C19.032 8.78535 20.857 8.78535 21.9826 7.65972Z" fill="#02AA51"/>
<path d="M22.0933 5.37956C22.0889 5.95088 21.9152 6.50807 21.5942 6.98067C21.2731 7.45326 20.8191 7.82002 20.2896 8.03455C19.76 8.24908 19.1788 8.30175 18.6193 8.18589C18.0598 8.07003 17.5473 7.79085 17.1465 7.38366C16.7457 6.97647 16.4747 6.45957 16.3677 5.89834C16.2608 5.3371 16.3226 4.75674 16.5455 4.23067C16.7685 3.70461 17.1424 3.25647 17.62 2.94295C18.0976 2.62942 18.6575 2.46458 19.2288 2.46929C19.6081 2.47216 19.983 2.54974 20.3322 2.69757C20.6814 2.84541 20.9981 3.06059 21.2641 3.33086C21.5301 3.60113 21.7403 3.92119 21.8825 4.27272C22.0248 4.62424 22.0964 5.00034 22.0933 5.37956Z" fill="#02AB50"/>
<path d="M21.2989 5.54193C21.5255 3.9618 20.4282 2.49717 18.8481 2.27059C17.268 2.04402 15.8033 3.1413 15.5768 4.72143C15.3502 6.30156 16.4475 7.76619 18.0276 7.99276C19.6077 8.21934 21.0723 7.12206 21.2989 5.54193Z" fill="#03AC4F"/>
<path d="M20.0076 6.71431C20.949 5.42242 20.6648 3.61199 19.3729 2.67061C18.081 1.72923 16.2706 2.0134 15.3293 3.3053C14.3879 4.59719 14.672 6.40759 15.9639 7.34897C17.2558 8.29034 19.0662 8.00621 20.0076 6.71431Z" fill="#03AD4E"/>
<path d="M17.8436 7.59424C19.3511 7.05597 20.1367 5.39758 19.5985 3.89011C19.0602 2.38263 17.4018 1.59692 15.8943 2.13519C14.3869 2.67346 13.6012 4.33188 14.1394 5.83936C14.6777 7.34683 16.3361 8.13251 17.8436 7.59424Z" fill="#03AE4D"/>
<path d="M18.5779 6.20196C19.3574 4.80139 18.854 3.03406 17.4534 2.25451C16.0529 1.47497 14.2855 1.97841 13.506 3.37898C12.7264 4.77954 13.2299 6.54687 14.6304 7.32641C16.031 8.10595 17.7983 7.60252 18.5779 6.20196Z" fill="#03AF4C"/>
<path d="M17.2819 6.88152C18.4177 5.74576 18.4177 3.90434 17.282 2.76859C16.1462 1.63284 14.3048 1.63284 13.169 2.76859C12.0333 3.90434 12.0333 5.74576 13.169 6.88152C14.3048 8.01727 16.1462 8.01727 17.2819 6.88152Z" fill="#03B04B"/>
<path d="M16.7659 6.58166C17.714 5.28242 17.4294 3.46059 16.1301 2.51247C14.8309 1.56436 13.0091 1.849 12.061 3.14823C11.1128 4.44747 11.3975 6.26931 12.6967 7.21742C13.9959 8.16553 15.8178 7.88089 16.7659 6.58166Z" fill="#03B14A"/>
<path d="M16.4144 5.93093C16.9097 4.39838 16.0688 2.75449 14.5363 2.25919C13.0038 1.7639 11.3599 2.60477 10.8646 4.13731C10.3693 5.66986 11.2101 7.31375 12.7427 7.80904C14.2752 8.30434 15.9191 7.46348 16.4144 5.93093Z" fill="#03B249"/>
<path d="M15.7669 5.19646C15.7621 5.77408 15.5862 6.33732 15.2613 6.81493C14.9364 7.29254 14.4771 7.66306 13.9416 7.87963C13.4061 8.09621 12.8184 8.14911 12.2528 8.03163C11.6873 7.91416 11.1692 7.63157 10.7643 7.21965C10.3593 6.80772 10.0856 6.28497 9.97783 5.71748C9.87002 5.14999 9.93292 4.56326 10.1586 4.03153C10.3843 3.4998 10.7626 3.04693 11.2457 2.73023C11.7288 2.41353 12.2949 2.24722 12.8725 2.25232C13.2559 2.25545 13.635 2.33409 13.988 2.48376C14.3409 2.63344 14.661 2.85122 14.9298 3.12464C15.1986 3.39805 15.4108 3.72175 15.5545 4.07724C15.6981 4.43273 15.7703 4.81306 15.7669 5.19646Z" fill="#03B349"/>
<path d="M15.0183 5.41362C15.0139 5.99204 14.8381 6.55616 14.5131 7.03465C14.1881 7.51314 13.7285 7.88452 13.1924 8.10176C12.6563 8.319 12.0678 8.37237 11.5014 8.25512C10.935 8.13788 10.4161 7.85529 10.0103 7.44309C9.60446 7.03088 9.33004 6.50758 9.22169 5.93939C9.11334 5.37119 9.17591 4.78364 9.40153 4.25103C9.62715 3.71841 10.0057 3.26467 10.4892 2.9472C10.9727 2.62973 11.5395 2.46278 12.1179 2.4675C12.8932 2.47381 13.6341 2.7877 14.178 3.34015C14.7219 3.8926 15.0241 4.63841 15.0183 5.41362Z" fill="#03B448"/>
<path d="M13.1147 8.00286C14.4101 7.0348 14.6755 5.19988 13.7075 3.90444C12.7394 2.60899 10.9045 2.34359 9.60905 3.31165C8.31361 4.27971 8.04819 6.11465 9.01625 7.4101C9.98431 8.70554 11.8193 8.97092 13.1147 8.00286Z" fill="#03B547"/>
<path d="M13.1044 7.58809C13.992 6.23366 13.6137 4.41608 12.2592 3.52842C10.9048 2.64075 9.08721 3.01913 8.19955 4.37357C7.31188 5.728 7.69027 7.54558 9.0447 8.43324C10.3991 9.32091 12.2167 8.94252 13.1044 7.58809Z" fill="#03B646"/>
<path d="M12.3346 8.11936C13.2998 6.81633 13.026 4.97752 11.723 4.01228C10.4199 3.04703 8.58114 3.32086 7.61589 4.62388C6.65065 5.92691 6.92447 7.7657 8.2275 8.73095C9.53053 9.69619 11.3693 9.42238 12.3346 8.11936Z" fill="#03B745"/>
<path d="M11.3989 8.83535C12.5471 7.68715 12.5471 5.82555 11.3989 4.67735C10.2507 3.52915 8.38906 3.52915 7.24086 4.67735C6.09266 5.82555 6.09266 7.68715 7.24086 8.83535C8.38906 9.98354 10.2507 9.98354 11.3989 8.83535Z" fill="#03B844"/>
<path d="M11.6417 7.22804C11.6373 7.81087 11.4602 8.37931 11.1327 8.86144C10.8052 9.34358 10.342 9.71773 9.80182 9.93658C9.26161 10.1554 8.66862 10.2091 8.09789 10.0909C7.52717 9.97261 7.00433 9.68773 6.59555 9.27226C6.18678 8.85679 5.91044 8.32941 5.80147 7.75684C5.69249 7.18427 5.75579 6.59222 5.98337 6.05564C6.21096 5.51906 6.59259 5.06207 7.07998 4.74243C7.56737 4.4228 8.13863 4.2549 8.72145 4.26C9.50213 4.26684 10.2482 4.58328 10.7957 5.13978C11.3432 5.69628 11.6475 6.44736 11.6417 7.22804Z" fill="#04B943"/>
<path d="M10.1902 9.77856C11.3415 8.62725 11.3415 6.7606 10.1902 5.60929C9.03885 4.45798 7.17221 4.458 6.0209 5.60931C4.86959 6.76062 4.86959 8.62724 6.0209 9.77855C7.17221 10.9299 9.03885 10.9299 10.1902 9.77856Z" fill="#04BA42"/>
<path d="M9.63613 10.307C10.789 9.15412 10.789 7.28495 9.63612 6.13209C8.48325 4.97922 6.6141 4.97922 5.46124 6.13209C4.30837 7.28495 4.30835 9.15412 5.46122 10.307C6.61408 11.4598 8.48327 11.4598 9.63613 10.307Z" fill="#04BB41"/>
<path d="M7.02814 11.7421C8.66184 11.7421 9.98621 10.4177 9.98621 8.78403C9.98621 7.15034 8.66184 5.82596 7.02814 5.82596C5.39444 5.82596 4.07007 7.15034 4.07007 8.78403C4.07007 10.4177 5.39444 11.7421 7.02814 11.7421Z" fill="#04BC40"/>
<path d="M8.63942 11.4753C9.79618 10.3186 9.79618 8.44311 8.63942 7.28635C7.48267 6.1296 5.6072 6.1296 4.45045 7.28635C3.29369 8.44311 3.29369 10.3186 4.45045 11.4753C5.6072 12.6321 7.48267 12.6321 8.63942 11.4753Z" fill="#04BD3F"/>
<path d="M9.07043 10.0328C9.06608 10.6195 8.88782 11.1917 8.55822 11.6771C8.22861 12.1625 7.76247 12.5392 7.21875 12.7596C6.67502 12.98 6.07813 13.0342 5.50359 12.9154C4.92905 12.7965 4.40266 12.5099 3.99101 12.0919C3.57937 11.6738 3.30096 11.1431 3.19099 10.5667C3.08103 9.99044 3.14446 9.39447 3.37324 8.85422C3.60203 8.31396 3.98588 7.85368 4.47629 7.53162C4.96669 7.20955 5.54161 7.04015 6.12829 7.04486C6.9146 7.05118 7.66621 7.36947 8.21791 7.92977C8.76962 8.49007 9.07627 9.24651 9.07043 10.0328Z" fill="#04BE3E"/>
<path d="M7.80185 12.7676C8.96172 11.6077 8.96172 9.72722 7.80185 8.56736C6.64198 7.40749 4.76146 7.40749 3.60159 8.56736C2.44173 9.72722 2.44173 11.6077 3.60159 12.7676C4.76146 13.9275 6.64198 13.9275 7.80185 12.7676Z" fill="#04BF3D"/>
<path d="M8.32079 11.3774C8.31801 11.966 8.14061 12.5406 7.81108 13.0283C7.48155 13.5161 7.01472 13.8951 6.46966 14.1173C5.9246 14.3395 5.32585 14.395 4.74923 14.2767C4.17261 14.1585 3.64406 13.8717 3.2305 13.4529C2.81694 13.034 2.53699 12.5018 2.42608 11.9238C2.31516 11.3457 2.37829 10.7477 2.60745 10.2055C2.83662 9.66334 3.22152 9.20137 3.71342 8.87808C4.20531 8.5548 4.78207 8.38473 5.37068 8.38945C6.1577 8.39577 6.91014 8.71381 7.46312 9.27388C8.01609 9.83394 8.32451 10.5904 8.32079 11.3774Z" fill="#04C03C"/>
<path d="M7.98468 12.6054C8.26717 10.9851 7.18271 9.44268 5.56245 9.16019C3.94219 8.8777 2.3997 9.96216 2.11721 11.5824C1.83472 13.2027 2.91921 14.7452 4.53946 15.0277C6.15972 15.3102 7.7022 14.2257 7.98468 12.6054Z" fill="#04C13B"/>
<path d="M7.76563 12.8296C7.76165 13.4209 7.58229 13.9978 7.25025 14.4872C6.9182 14.9765 6.44842 15.3564 5.90037 15.5785C5.35232 15.8007 4.75065 15.8553 4.17157 15.7353C3.59249 15.6154 3.06203 15.3262 2.64737 14.9046C2.23271 14.483 1.95251 13.9478 1.84222 13.3668C1.73193 12.7858 1.79652 12.1851 2.02782 11.6408C2.25913 11.0966 2.64674 10.6332 3.14157 10.3093C3.6364 9.98551 4.21621 9.8158 4.80756 9.8217C5.59828 9.82959 6.35363 10.1506 6.9081 10.7144C7.46257 11.2782 7.77094 12.0388 7.76563 12.8296Z" fill="#04C23A"/>
<path d="M6.67109 15.6765C7.83718 14.5104 7.83718 12.6198 6.67109 11.4537C5.505 10.2876 3.61439 10.2876 2.4483 11.4537C1.28222 12.6198 1.28222 14.5104 2.4483 15.6765C3.61439 16.8426 5.505 16.8426 6.67109 15.6765Z" fill="#04C339"/>
<path d="M7.39081 14.3775C7.38528 14.9682 7.20481 15.5439 6.87218 16.0321C6.53955 16.5202 6.06969 16.8988 5.52198 17.12C4.97427 17.3413 4.37327 17.3952 3.79494 17.275C3.2166 17.1548 2.68687 16.8658 2.27268 16.4447C1.85849 16.0235 1.57841 15.4891 1.46784 14.9088C1.35728 14.3285 1.4212 13.7285 1.6515 13.1846C1.88181 12.6406 2.26816 12.1771 2.76177 11.8527C3.25538 11.5282 3.83409 11.3573 4.42477 11.3616C4.81766 11.3645 5.20613 11.4448 5.56794 11.598C5.92975 11.7512 6.2578 11.9743 6.53331 12.2544C6.80881 12.5345 7.02636 12.8662 7.1735 13.2305C7.32064 13.5948 7.39449 13.9846 7.39081 14.3775Z" fill="#04C439"/>
<path d="M6.38916 17.3025C7.56791 16.1429 7.58344 14.2473 6.42387 13.0686C5.2643 11.8898 3.36874 11.8743 2.18999 13.0338C1.01124 14.1934 0.995669 16.089 2.15524 17.2677C3.31481 18.4465 5.21041 18.462 6.38916 17.3025Z" fill="#04C538"/>
<path d="M6.35953 18.0808C7.53029 16.91 7.53027 15.0119 6.35952 13.8411C5.18876 12.6703 3.29061 12.6703 2.11985 13.8411C0.949093 15.0119 0.949076 16.91 2.11983 18.0808C3.29059 19.2515 5.18878 19.2515 6.35953 18.0808Z" fill="#05C637"/>
<path d="M6.76836 18.3749C7.66378 16.9796 7.25855 15.1225 5.86325 14.2271C4.46795 13.3317 2.61096 13.7369 1.71554 15.1322C0.820115 16.5275 1.22534 18.3845 2.62064 19.2799C4.01594 20.1754 5.87294 19.7702 6.76836 18.3749Z" fill="#05C736"/>
<path d="M4.3089 20.6002C5.9701 20.6002 7.31677 19.2535 7.31677 17.5923C7.31677 15.9311 5.9701 14.5845 4.3089 14.5845C2.64769 14.5845 1.30103 15.9311 1.30103 17.5923C1.30103 19.2535 2.64769 20.6002 4.3089 20.6002Z" fill="#05C835"/>
<path d="M7.43865 18.4092C7.43391 19.0048 7.25263 19.5857 6.91776 20.0784C6.58288 20.571 6.10946 20.9533 5.55732 21.1769C5.00519 21.4004 4.39915 21.4553 3.81586 21.3344C3.23257 21.2136 2.69821 20.9225 2.28035 20.4979C1.8625 20.0734 1.57992 19.5345 1.46834 18.9493C1.35677 18.3642 1.42122 17.7591 1.65353 17.2106C1.88584 16.6621 2.27556 16.1948 2.77346 15.8678C3.27135 15.5408 3.85504 15.3687 4.4507 15.3734C4.84623 15.3766 5.23726 15.4576 5.60148 15.6118C5.9657 15.7661 6.29598 15.9906 6.57344 16.2725C6.8509 16.5544 7.07011 16.8881 7.21857 17.2548C7.36702 17.6214 7.4418 18.0137 7.43865 18.4092Z" fill="#05C934"/>
<path d="M7.61387 19.1819C7.60911 19.7785 7.42751 20.3603 7.09202 20.8537C6.75653 21.347 6.28223 21.7298 5.72913 21.9536C5.17604 22.1773 4.56899 22.232 3.98482 22.1107C3.40065 21.9893 2.86562 21.6974 2.44737 21.2719C2.02913 20.8465 1.74647 20.3065 1.63519 19.7203C1.52392 19.1341 1.58901 18.5281 1.82223 17.979C2.05545 17.4298 2.44634 16.9621 2.94541 16.6352C3.44448 16.3082 4.0293 16.1366 4.62592 16.1421C5.42508 16.1495 6.18866 16.4738 6.7489 17.0437C7.30914 17.6137 7.62024 18.3827 7.61387 19.1819Z" fill="#05CA33"/>
<path d="M7.84106 19.937C7.8363 20.5346 7.65433 21.1173 7.31822 21.6114C6.9821 22.1055 6.50695 22.4888 5.9529 22.7128C5.39885 22.9367 4.79081 22.9912 4.20577 22.8694C3.62072 22.7476 3.08498 22.4549 2.66635 22.0285C2.24771 21.602 1.96502 21.0609 1.85404 20.4737C1.74307 19.8865 1.8088 19.2796 2.04293 18.7298C2.27707 18.18 2.66908 17.712 3.16932 17.3851C3.66956 17.0581 4.25555 16.887 4.85311 16.8933C5.65264 16.9017 6.4162 17.2269 6.97633 17.7974C7.53646 18.368 7.84743 19.1375 7.84106 19.937Z" fill="#05CB32"/>
<path d="M7.23037 22.7834C8.41124 21.6025 8.41124 19.6879 7.23037 18.5071C6.0495 17.3262 4.13493 17.3262 2.95406 18.5071C1.77319 19.688 1.77319 21.6025 2.95406 22.7834C4.13493 23.9642 6.0495 23.9643 7.23037 22.7834Z" fill="#05CB31"/>
<path d="M8.4383 21.3753C8.43355 21.9742 8.2513 22.5583 7.91459 23.0536C7.57787 23.5489 7.10182 23.9332 6.54666 24.1579C5.9915 24.3826 5.38215 24.4376 4.79572 24.316C4.20928 24.1944 3.67209 23.9016 3.25209 23.4746C2.83209 23.0476 2.54815 22.5057 2.43619 21.9173C2.32422 21.329 2.38928 20.7206 2.6231 20.1693C2.85693 19.6179 3.24901 19.1482 3.74979 18.8197C4.25056 18.4911 4.83752 18.3185 5.43642 18.3236C5.83395 18.3268 6.22694 18.4082 6.59299 18.5633C6.95903 18.7184 7.29093 18.944 7.56971 19.2274C7.84849 19.5108 8.0687 19.8464 8.21773 20.215C8.36677 20.5835 8.44172 20.9778 8.4383 21.3753Z" fill="#05CC30"/>
<path d="M8.80834 22.0563C8.80359 22.6559 8.62115 23.2407 8.28408 23.7366C7.94702 24.2325 7.47048 24.6173 6.9147 24.8424C6.35893 25.0675 5.7489 25.1227 5.16174 25.0011C4.57458 24.8795 4.03668 24.5865 3.61604 24.1592C3.1954 23.7318 2.91091 23.1894 2.79856 22.6004C2.68622 22.0114 2.75106 21.4023 2.98487 20.8501C3.21869 20.298 3.61099 19.8275 4.11216 19.4983C4.61333 19.1691 5.20087 18.9959 5.80047 19.0006C6.19861 19.0038 6.59223 19.0853 6.95886 19.2405C7.3255 19.3958 7.65798 19.6218 7.93729 19.9055C8.2166 20.1893 8.43727 20.5252 8.58672 20.8943C8.73618 21.2633 8.81149 21.6582 8.80834 22.0563Z" fill="#05CD2F"/>
<path d="M9.22094 22.7097C9.21619 23.3105 9.03333 23.8965 8.69552 24.3935C8.35771 24.8904 7.8801 25.276 7.32312 25.5014C6.76614 25.7269 6.1548 25.7821 5.56643 25.6601C4.97807 25.5381 4.43912 25.2444 4.01772 24.816C3.59633 24.3876 3.31142 23.8439 3.19908 23.2536C3.08673 22.6634 3.15198 22.053 3.38655 21.4998C3.62113 20.9466 4.01449 20.4754 4.51691 20.1457C5.01932 19.8161 5.60819 19.6429 6.20906 19.648C7.01438 19.6549 7.78404 19.9812 8.34882 20.5553C8.9136 21.1295 9.2273 21.9043 9.22094 22.7097Z" fill="#05CE2E"/>
<path d="M9.67567 23.3411C9.67131 23.9428 9.48857 24.5297 9.15056 25.0276C8.81255 25.5255 8.33446 25.9119 7.77678 26.1379C7.2191 26.364 6.60691 26.4196 6.01764 26.2977C5.42836 26.1757 4.8885 25.8817 4.46635 25.4529C4.0442 25.024 3.75873 24.4796 3.64607 23.8885C3.53341 23.2974 3.59861 22.6861 3.83343 22.1321C4.06825 21.578 4.46213 21.1061 4.96524 20.7759C5.46835 20.4458 6.05809 20.2723 6.65982 20.2774C7.46586 20.2843 8.23623 20.6107 8.8017 21.1852C9.36717 21.7596 9.6815 22.535 9.67567 23.3411Z" fill="#05CF2D"/>
<path d="M9.24967 26.0577C10.4536 24.8845 10.4785 22.9575 9.30528 21.7536C8.13208 20.5497 6.20505 20.5248 5.00115 21.698C3.79725 22.8712 3.77236 24.7983 4.94556 26.0022C6.11876 27.2061 8.04577 27.2309 9.24967 26.0577Z" fill="#05D02C"/>
<path d="M10.6991 24.4786C10.6939 25.0813 10.5102 25.6689 10.1712 26.1671C9.83212 26.6654 9.35297 27.052 8.79426 27.278C8.23554 27.504 7.62236 27.5592 7.03224 27.4368C6.44213 27.3143 5.90156 27.0196 5.47889 26.59C5.05622 26.1604 4.77042 25.6151 4.65762 25.023C4.54482 24.431 4.61009 23.8188 4.84516 23.2639C5.08024 22.7089 5.47457 22.2361 5.97832 21.9052C6.48206 21.5744 7.07261 21.4003 7.67528 21.405C8.07568 21.4081 8.47154 21.4901 8.84024 21.6463C9.20894 21.8025 9.54325 22.0298 9.82406 22.3152C10.1049 22.6007 10.3267 22.9386 10.4768 23.3098C10.627 23.681 10.7025 24.0782 10.6991 24.4786Z" fill="#06D12B"/>
<path d="M11.2674 24.9988C11.2627 25.6028 11.0789 26.1919 10.7394 26.6914C10.3998 27.191 9.91971 27.5786 9.35981 27.8053C8.79992 28.032 8.18537 28.0875 7.59391 27.9649C7.00245 27.8422 6.46065 27.547 6.03703 27.1164C5.61341 26.6858 5.32699 26.1392 5.21403 25.5459C5.10107 24.9525 5.16663 24.3389 5.40241 23.7828C5.63819 23.2267 6.03361 22.753 6.53865 22.4216C7.04368 22.0902 7.63563 21.9161 8.23965 21.9212C8.64058 21.9243 9.03695 22.0064 9.40614 22.1628C9.77532 22.3192 10.1101 22.5468 10.3913 22.8326C10.6724 23.1184 10.8945 23.4568 11.0449 23.8285C11.1952 24.2002 11.2708 24.5979 11.2674 24.9988Z" fill="#06D22A"/>
<path d="M8.8106 28.5144C10.4993 28.5144 11.8683 27.1455 11.8683 25.4568C11.8683 23.7681 10.4993 22.3991 8.8106 22.3991C7.1219 22.3991 5.75293 23.7681 5.75293 25.4568C5.75293 27.1455 7.1219 28.5144 8.8106 28.5144Z" fill="#06D32A"/>
<path d="M11.6053 28.0627C12.801 26.8671 12.801 24.9286 11.6053 23.7329C10.4097 22.5373 8.47116 22.5373 7.27551 23.7329C6.07986 24.9286 6.07986 26.8671 7.27551 28.0627C8.47116 29.2584 10.4097 29.2584 11.6053 28.0627Z" fill="#06D429"/>
<path d="M13.1654 26.3293C13.1606 26.9357 12.9762 27.527 12.6353 28.0285C12.2944 28.5301 11.8124 28.9192 11.2504 29.1468C10.6883 29.3744 10.0713 29.4302 9.47755 29.3071C8.88377 29.184 8.33984 28.8876 7.91454 28.4553C7.48925 28.0231 7.20171 27.4744 7.08828 26.8787C6.97486 26.283 7.04066 25.667 7.27734 25.1087C7.51403 24.5504 7.91098 24.0748 8.41798 23.7422C8.92498 23.4095 9.51924 23.2346 10.1256 23.2397C10.9383 23.2466 11.7151 23.5759 12.2851 24.1552C12.8551 24.7346 13.1717 25.5166 13.1654 26.3293Z" fill="#06D528"/>
<path d="M13.8572 26.6778C13.8524 27.285 13.6677 27.8771 13.3264 28.3793C12.9851 28.8815 12.5025 29.2712 11.9397 29.4991C11.3769 29.7269 10.7592 29.7828 10.1646 29.6596C9.57005 29.5363 9.0254 29.2395 8.59955 28.8067C8.1737 28.3739 7.88578 27.8245 7.7722 27.228C7.65862 26.6315 7.72449 26.0148 7.96148 25.4557C8.19846 24.8967 8.59592 24.4205 9.10357 24.0874C9.61123 23.7543 10.2063 23.5792 10.8135 23.5843C11.2165 23.5874 11.6149 23.6699 11.986 23.8271C12.3572 23.9843 12.6936 24.2131 12.9763 24.5004C13.259 24.7877 13.4823 25.1278 13.6334 25.5015C13.7845 25.8751 13.8606 26.2748 13.8572 26.6778Z" fill="#06D627"/>
<path d="M14.5119 27.4959C14.818 25.8262 13.7127 24.2245 12.043 23.9184C10.3733 23.6122 8.77159 24.7176 8.46545 26.3873C8.15931 28.0569 9.26468 29.6586 10.9344 29.9648C12.604 30.2709 14.2057 29.1656 14.5119 27.4959Z" fill="#06D726"/>
<path d="M15.317 27.2496C15.3122 27.8582 15.1271 28.4518 14.785 28.9553C14.4429 29.4587 13.9592 29.8494 13.395 30.078C12.8309 30.3065 12.2116 30.3627 11.6156 30.2393C11.0196 30.1159 10.4735 29.8185 10.0464 29.3848C9.61939 28.9511 9.33055 28.4004 9.21643 27.8026C9.10232 27.2047 9.16806 26.5864 9.40535 26.0258C9.64263 25.4653 10.0408 24.9877 10.5495 24.6535C11.0582 24.3193 11.6546 24.1434 12.2633 24.1481C12.6674 24.1512 13.067 24.2339 13.4392 24.3915C13.8114 24.549 14.1489 24.7784 14.4325 25.0664C14.7161 25.3544 14.9401 25.6954 15.0919 26.07C15.2436 26.4446 15.3201 26.8454 15.317 27.2496Z" fill="#06D825"/>
<path d="M16.0798 27.4627C16.0746 28.0721 15.8889 28.6664 15.546 29.1702C15.2032 29.6741 14.7186 30.065 14.1536 30.2935C13.5885 30.522 12.9685 30.5778 12.3717 30.4539C11.775 30.33 11.2284 30.0319 10.8011 29.5973C10.3738 29.1627 10.0849 28.6112 9.97102 28.0125C9.85714 27.4138 9.92337 26.7947 10.1613 26.2336C10.3993 25.6725 10.7982 25.1946 11.3078 24.8602C11.8174 24.5259 12.4147 24.3501 13.0241 24.3552C13.4288 24.3586 13.8288 24.4417 14.2014 24.5997C14.574 24.7577 14.9118 24.9875 15.1956 25.2761C15.4793 25.5646 15.7034 25.9062 15.8552 26.2814C16.0069 26.6566 16.0832 27.058 16.0798 27.4627Z" fill="#06D924"/>
<path d="M16.8649 27.6221C16.8597 28.2323 16.6737 28.8273 16.3304 29.3319C15.9871 29.8364 15.5019 30.2278 14.9362 30.4566C14.3704 30.6854 13.7495 30.7413 13.152 30.6172C12.5545 30.4931 12.0072 30.1947 11.5793 29.7595C11.1515 29.3244 10.8622 28.7722 10.7482 28.1727C10.6342 27.5732 10.7005 26.9533 10.9387 26.3915C11.177 25.8296 11.5765 25.3511 12.0867 25.0163C12.5969 24.6815 13.1949 24.5056 13.8052 24.5107C14.2104 24.514 14.611 24.5972 14.984 24.7554C15.3571 24.9136 15.6954 25.1437 15.9795 25.4327C16.2636 25.7216 16.488 26.0637 16.6399 26.4393C16.7918 26.815 16.8683 27.2169 16.8649 27.6221Z" fill="#06DA23"/>
<path d="M17.667 27.7236C17.6619 28.3346 17.4757 28.9304 17.1319 29.4356C16.7882 29.9408 16.3024 30.3327 15.7359 30.5618C15.1694 30.7909 14.5477 30.8468 13.9494 30.7226C13.3512 30.5984 12.8032 30.2995 12.3747 29.8638C11.9463 29.4281 11.6567 28.8752 11.5425 28.2749C11.4283 27.6746 11.4946 27.054 11.7332 26.4914C11.9717 25.9289 12.3718 25.4497 12.8826 25.1145C13.3935 24.7793 13.9923 24.603 14.6034 24.6081C15.0091 24.6115 15.4102 24.6948 15.7837 24.8532C16.1573 25.0116 16.496 25.242 16.7805 25.5313C17.0649 25.8206 17.2897 26.1631 17.4418 26.5393C17.5939 26.9154 17.6704 27.3179 17.667 27.7236Z" fill="#06DB22"/>
<path d="M18.4878 27.7792C18.4831 28.3915 18.2969 28.9886 17.9527 29.4951C17.6085 30.0015 17.1219 30.3945 16.5544 30.6244C15.9868 30.8542 15.3639 30.9106 14.7643 30.7863C14.1647 30.6621 13.6155 30.3628 13.186 29.9264C12.7565 29.4899 12.4661 28.936 12.3516 28.3345C12.237 27.733 12.3034 27.111 12.5423 26.5472C12.7813 25.9835 13.182 25.5032 13.6939 25.1673C14.2059 24.8313 14.8059 24.6547 15.4182 24.6598C16.2389 24.6666 17.0232 24.9991 17.5988 25.584C18.1744 26.169 18.4942 26.9586 18.4878 27.7792Z" fill="#06DC21"/>
<path d="M19.3188 27.7733C19.314 28.3864 19.1276 28.9843 18.783 29.4914C18.4384 29.9985 17.9511 30.392 17.3829 30.6222C16.8146 30.8523 16.1908 30.9088 15.5905 30.7844C14.9901 30.66 14.4402 30.3603 14.0102 29.9233C13.5801 29.4863 13.2894 28.9316 13.1746 28.3293C13.0599 27.7271 13.1264 27.1043 13.3656 26.5398C13.6049 25.9753 14.0061 25.4945 14.5187 25.1581C15.0313 24.8217 15.6321 24.6448 16.2452 24.6499C16.6521 24.653 17.0545 24.7363 17.4292 24.895C17.8039 25.0537 18.1437 25.2847 18.4292 25.5748C18.7146 25.8648 18.9401 26.2083 19.0927 26.5855C19.2454 26.9628 19.3222 27.3664 19.3188 27.7733Z" fill="#06DD20"/>
<path d="M19.4826 29.5405C20.5261 28.1821 20.2708 26.235 18.9124 25.1915C17.554 24.148 15.6069 24.4033 14.5634 25.7617C13.52 27.1201 13.7753 29.0672 15.1337 30.1107C16.4921 31.1541 18.4391 30.8988 19.4826 29.5405Z" fill="#07DE1F"/>
<path d="M20.9241 27.5899C20.9193 28.2047 20.7323 28.8042 20.3868 29.3126C20.0412 29.8211 19.5526 30.2156 18.9828 30.4463C18.4129 30.677 17.7875 30.7335 17.1855 30.6086C16.5836 30.4837 16.0322 30.1831 15.6011 29.7448C15.1701 29.3065 14.8787 28.7502 14.7639 28.1463C14.6491 27.5423 14.716 26.9179 14.9562 26.352C15.1963 25.7861 15.5989 25.3041 16.1131 24.9671C16.6272 24.63 17.2298 24.4531 17.8445 24.4586C18.668 24.4659 19.4548 24.7999 20.0323 25.387C20.6097 25.9741 20.9305 26.7664 20.9241 27.5899Z" fill="#07DF1E"/>
<path d="M21.7091 27.4187C21.7044 28.0341 21.5172 28.6344 21.1713 29.1434C20.8254 29.6525 20.3363 30.0475 19.7659 30.2786C19.1954 30.5096 18.5692 30.5663 17.9666 30.4414C17.3639 30.3166 16.8118 30.0158 16.3801 29.5771C15.9484 29.1385 15.6565 28.5816 15.5413 27.977C15.4261 27.3724 15.4928 26.7473 15.733 26.1806C15.9731 25.6139 16.376 25.1312 16.8905 24.7935C17.405 24.4558 18.0081 24.2782 18.6236 24.2833C19.4485 24.2902 20.2369 24.6243 20.8155 25.2122C21.3941 25.8002 21.7155 26.5938 21.7091 27.4187Z" fill="#07E01D"/>
<path d="M22.462 27.1956C22.4568 27.8117 22.2691 28.4125 21.9225 28.9219C21.5759 29.4314 21.0861 29.8266 20.5148 30.0577C19.9436 30.2887 19.3168 30.3452 18.7134 30.22C18.1101 30.0947 17.5575 29.7934 17.1255 29.3541C16.6934 28.9148 16.4013 28.3573 16.2861 27.752C16.1709 27.1467 16.2378 26.5208 16.4783 25.9535C16.7189 25.3862 17.1222 24.903 17.6373 24.5649C18.1525 24.2268 18.7563 24.0491 19.3724 24.0542C19.7816 24.0576 20.186 24.1415 20.5627 24.3012C20.9394 24.4609 21.2809 24.6933 21.5678 24.985C21.8547 25.2767 22.0813 25.6221 22.2348 26.0013C22.3882 26.3806 22.4654 26.7864 22.462 27.1956Z" fill="#07E11C"/>
<path d="M23.197 26.9269C23.1918 27.5438 23.0039 28.1453 22.6569 28.6555C22.3098 29.1656 21.8194 29.5613 21.2474 29.7926C20.6755 30.024 20.0478 30.0806 19.4437 29.9552C18.8396 29.8298 18.2863 29.5281 17.8537 29.0882C17.4211 28.6484 17.1286 28.0901 17.0133 27.484C16.8979 26.878 16.9649 26.2513 17.2057 25.6833C17.4465 25.1152 17.8503 24.6314 18.3661 24.2929C18.8819 23.9544 19.4865 23.7764 20.1035 23.7815C20.5131 23.7849 20.9181 23.869 21.2953 24.0289C21.6724 24.1888 22.0144 24.4214 22.3017 24.7135C22.5889 25.0055 22.8159 25.3513 22.9695 25.7311C23.1231 26.1109 23.2004 26.5172 23.197 26.9269Z" fill="#07E21B"/>
<path d="M22.9635 28.7848C24.2004 27.5824 24.2283 25.6049 23.0259 24.368C21.8235 23.1312 19.8461 23.1032 18.6092 24.3056C17.3723 25.508 17.3444 27.4854 18.5468 28.7223C19.7492 29.9592 21.7266 29.9872 22.9635 28.7848Z" fill="#07E31A"/>
<path d="M24.5911 26.2376C24.5859 26.8561 24.3975 27.4592 24.0496 27.9706C23.7017 28.482 23.21 28.8788 22.6366 29.1108C22.0632 29.3427 21.4339 29.3994 20.8283 29.2737C20.2227 29.148 19.6679 28.8456 19.2342 28.4046C18.8004 27.9636 18.5072 27.404 18.3916 26.7963C18.2759 26.1887 18.3431 25.5604 18.5845 24.991C18.8259 24.4215 19.2307 23.9364 19.7479 23.597C20.265 23.2576 20.8711 23.0792 21.4896 23.0843C21.9004 23.0874 22.3065 23.1715 22.6848 23.3317C23.063 23.4919 23.406 23.7251 23.694 24.018C23.9821 24.3109 24.2096 24.6576 24.3635 25.0385C24.5174 25.4194 24.5948 25.8268 24.5911 26.2376Z" fill="#07E41A"/>
<path d="M25.2487 25.8292C25.2436 26.4485 25.0549 27.0523 24.7066 27.5644C24.3582 28.0765 23.8659 28.4738 23.2918 28.706C22.7177 28.9383 22.0876 28.995 21.4812 28.8692C20.8748 28.7433 20.3193 28.4405 19.885 27.999C19.4507 27.5575 19.1571 26.9971 19.0413 26.3887C18.9255 25.7803 18.9927 25.1512 19.2344 24.581C19.4761 24.0108 19.8815 23.5251 20.3993 23.1852C20.9171 22.8454 21.524 22.6668 22.1433 22.6719C22.5545 22.6753 22.961 22.7596 23.3396 22.9201C23.7182 23.0806 24.0615 23.3141 24.3499 23.6073C24.6383 23.9005 24.8661 24.2476 25.0203 24.6288C25.1745 25.0101 25.2521 25.4179 25.2487 25.8292Z" fill="#07E519"/>
<path d="M25.8739 25.379C25.8687 25.999 25.6798 26.6037 25.3311 27.1164C24.9823 27.6291 24.4893 28.0269 23.9145 28.2595C23.3396 28.492 22.7087 28.5489 22.1016 28.4229C21.4944 28.2969 20.9382 27.9937 20.5034 27.5516C20.0685 27.1095 19.7745 26.5484 19.6586 25.9392C19.5426 25.3301 19.6099 24.7002 19.8519 24.1293C20.094 23.5584 20.4998 23.072 21.0183 22.7317C21.5367 22.3915 22.1443 22.2126 22.7644 22.2177C23.1762 22.2211 23.5832 22.3056 23.9623 22.4663C24.3414 22.627 24.6852 22.8608 24.9739 23.1543C25.2627 23.4479 25.4907 23.7954 25.6452 24.1771C25.7996 24.5588 25.8773 24.9672 25.8739 25.379Z" fill="#07E618"/>
<path d="M26.4678 24.887C26.4626 25.5078 26.2735 26.1133 25.9243 26.6266C25.5751 27.14 25.0815 27.5383 24.5059 27.7712C23.9303 28.004 23.2986 28.061 22.6907 27.9348C22.0827 27.8087 21.5259 27.5051 21.0905 27.0624C20.6551 26.6198 20.3607 26.058 20.2446 25.448C20.1285 24.8381 20.1958 24.2074 20.4382 23.6358C20.6805 23.0641 21.0869 22.5771 21.6059 22.2365C22.125 21.8958 22.7335 21.7166 23.3543 21.7217C23.7666 21.7251 24.1742 21.8097 24.5538 21.9706C24.9333 22.1315 25.2775 22.3656 25.5666 22.6595C25.8557 22.9534 26.0841 23.3014 26.2388 23.6836C26.3934 24.0658 26.4712 24.4747 26.4678 24.887Z" fill="#07E717"/>
<path d="M27.0273 24.3591C27.0221 24.9807 26.8328 25.5869 26.4831 26.1009C26.1335 26.615 25.6392 27.0138 25.0629 27.2469C24.4866 27.4801 23.8541 27.5371 23.2454 27.4108C22.6367 27.2845 22.0791 26.9805 21.6432 26.5373C21.2072 26.0941 20.9124 25.5316 20.7962 24.9209C20.6799 24.3102 20.7474 23.6787 20.99 23.1063C21.2326 22.5339 21.6395 22.0464 22.1592 21.7052C22.679 21.3641 23.2882 21.1847 23.9098 21.1898C24.3226 21.1932 24.7307 21.2779 25.1108 21.439C25.4909 21.6001 25.8355 21.8345 26.1249 22.1288C26.4144 22.4231 26.6431 22.7715 26.7979 23.1542C26.9528 23.5368 27.0307 23.9463 27.0273 24.3591Z" fill="#07E816"/>
<path d="M27.5514 23.7952C27.5467 24.4182 27.3573 25.0258 27.0071 25.5412C26.6569 26.0565 26.1618 26.4564 25.5843 26.6902C25.0068 26.9241 24.373 26.9814 23.7629 26.8549C23.1528 26.7284 22.594 26.4238 22.1571 25.9797C21.7202 25.5355 21.4248 24.9717 21.3084 24.3597C21.192 23.7476 21.2597 23.1148 21.5031 22.5412C21.7464 21.9677 22.1544 21.4792 22.6754 21.1375C23.1964 20.7959 23.807 20.6165 24.43 20.622C24.8434 20.6254 25.2519 20.7102 25.6325 20.8715C26.013 21.0328 26.3581 21.2674 26.648 21.5621C26.9378 21.8568 27.1668 22.2056 27.3218 22.5888C27.4768 22.972 27.5549 23.3819 27.5514 23.7952Z" fill="#07E915"/>
<path d="M28.0373 23.1977C28.0306 23.821 27.8393 24.4283 27.4877 24.9429C27.136 25.4575 26.6396 25.8564 26.0614 26.089C25.4831 26.3217 24.8489 26.3778 24.2388 26.2503C23.6287 26.1227 23.0701 25.8171 22.6335 25.3722C22.197 24.9273 21.9021 24.363 21.7861 23.7506C21.67 23.1382 21.7381 22.5051 21.9817 21.9314C22.2253 21.3577 22.6335 20.869 23.1547 20.5271C23.6758 20.1852 24.2866 20.0055 24.9099 20.0106C25.3247 20.0139 25.7348 20.0991 26.1166 20.2612C26.4984 20.4233 26.8444 20.6592 27.1349 20.9552C27.4255 21.2513 27.6547 21.6017 27.8095 21.9865C27.9644 22.3713 28.0418 22.7829 28.0373 23.1977Z" fill="#07EA14"/>
<path d="M28.4819 22.5683C28.4759 23.1927 28.2851 23.8012 27.9333 24.317C27.5816 24.8329 27.0848 25.2329 26.5057 25.4664C25.9267 25.6999 25.2914 25.7566 24.6802 25.6291C24.069 25.5016 23.5093 25.1959 23.0719 24.7503C22.6345 24.3048 22.3389 23.7396 22.2227 23.1262C22.1064 22.5128 22.1747 21.8787 22.4188 21.304C22.6629 20.7293 23.0719 20.2399 23.594 19.8977C24.1162 19.5554 24.7282 19.3757 25.3525 19.3812C25.7674 19.3846 26.1775 19.4697 26.5594 19.6318C26.9413 19.7939 27.2875 20.0297 27.5782 20.3257C27.8689 20.6218 28.0983 20.9722 28.2534 21.357C28.4084 21.7418 28.4861 22.1535 28.4819 22.5683Z" fill="#08EB13"/>
<path d="M28.88 21.9093C28.8749 22.5345 28.6845 23.1441 28.3328 23.6611C27.9812 24.1781 27.4842 24.5792 26.9046 24.8137C26.325 25.0482 25.6889 25.1056 25.0768 24.9786C24.4646 24.8516 23.9038 24.5459 23.4653 24.1002C23.0268 23.6545 22.7304 23.0888 22.6134 22.4746C22.4965 21.8604 22.5643 21.2253 22.8082 20.6497C23.0522 20.074 23.4614 19.5836 23.9841 19.2405C24.5068 18.8974 25.1195 18.717 25.7447 18.7221C26.1599 18.7252 26.5704 18.8102 26.9528 18.9721C27.3351 19.134 27.6818 19.3697 27.973 19.6657C28.2642 19.9617 28.4942 20.3122 28.6499 20.6972C28.8055 21.0822 28.8837 21.494 28.88 21.9093Z" fill="#08EC12"/>
<path d="M29.2447 21.2219C29.2403 21.8481 29.0504 22.4589 28.6988 22.9771C28.3472 23.4952 27.8498 23.8975 27.2696 24.1329C26.6893 24.3683 26.0523 24.4264 25.4391 24.2997C24.8258 24.173 24.264 23.8672 23.8245 23.4211C23.3851 22.975 23.0879 22.4085 22.9705 21.7935C22.853 21.1784 22.9207 20.5423 23.1649 19.9657C23.409 19.3891 23.8187 18.8978 24.3421 18.5541C24.8656 18.2104 25.4792 18.0297 26.1054 18.0348C26.9441 18.0416 27.7459 18.3811 28.3345 18.9787C28.9232 19.5763 29.2505 20.3831 29.2447 21.2219Z" fill="#08ED11"/>
<path d="M29.5571 20.5085C29.5536 21.1358 29.364 21.7478 29.0123 22.2672C28.6607 22.7866 28.1628 23.19 27.5818 23.4262C27.0008 23.6625 26.3627 23.7211 25.7484 23.5945C25.1341 23.4679 24.5711 23.1619 24.1308 22.7152C23.6905 22.2684 23.3927 21.7011 23.275 21.085C23.1574 20.469 23.2251 19.8318 23.4698 19.2542C23.7145 18.6767 24.125 18.1848 24.6494 17.8407C25.1739 17.4966 25.7886 17.3159 26.4158 17.3214C27.2546 17.3288 28.0563 17.6683 28.6451 18.2658C29.234 18.8632 29.5619 19.6697 29.5571 20.5085Z" fill="#08EE10"/>
<path d="M29.8248 19.7698C29.8197 20.3973 29.6285 21.0093 29.2756 21.5283C28.9227 22.0472 28.4238 22.4498 27.842 22.6853C27.2603 22.9207 26.6218 22.9783 26.0072 22.8508C25.3927 22.7234 24.8298 22.4166 24.3897 21.9692C23.9496 21.5218 23.652 20.954 23.5345 20.3374C23.4171 19.7209 23.4852 19.0834 23.73 18.5056C23.9749 17.9278 24.3857 17.4355 24.9103 17.0911C25.4349 16.7467 26.0499 16.5656 26.6775 16.5707C27.0942 16.5741 27.5062 16.6595 27.8899 16.8221C28.2736 16.9847 28.6215 17.2213 28.9137 17.5184C29.206 17.8154 29.4369 18.1672 29.5932 18.5535C29.7495 18.9398 29.8282 19.3531 29.8248 19.7698Z" fill="#08EF0F"/>
<path d="M30.0395 19.0086C30.0367 19.6399 29.8465 20.2561 29.493 20.7791C29.1395 21.3021 28.6387 21.7084 28.054 21.9464C27.4693 22.1844 26.8271 22.2434 26.2088 22.1159C25.5905 21.9885 25.024 21.6802 24.5812 21.2304C24.1383 20.7805 23.839 20.2092 23.7212 19.589C23.6035 18.9688 23.6725 18.3276 23.9197 17.7467C24.1668 17.1658 24.5809 16.6714 25.1094 16.3262C25.6379 15.9809 26.257 15.8004 26.8883 15.8075C27.3054 15.8106 27.7178 15.8959 28.102 16.0585C28.4862 16.2211 28.8345 16.4578 29.1272 16.7551C29.4199 17.0524 29.651 17.4044 29.8076 17.791C29.9641 18.1777 30.043 18.5914 30.0395 19.0086Z" fill="#08F00E"/>
<path d="M30.2031 18.2297C30.1991 18.8605 30.008 19.476 29.654 19.9982C29.3 20.5203 28.7989 20.9257 28.2144 21.1629C27.6298 21.4001 26.988 21.4585 26.3702 21.3307C25.7525 21.2028 25.1865 20.8946 24.7441 20.4449C24.3016 19.9952 24.0026 19.4243 23.8849 18.8045C23.7671 18.1847 23.8359 17.5439 24.0826 16.9633C24.3293 16.3827 24.7428 15.8883 25.2706 15.5429C25.7985 15.1974 26.417 15.0163 27.0478 15.0226C27.891 15.031 28.6965 15.3733 29.2879 15.9744C29.8793 16.5755 30.2084 17.3865 30.2031 18.2297Z" fill="#08F10D"/>
<path d="M30.3127 17.429C30.3079 18.0595 30.1163 18.6745 29.762 19.196C29.4077 19.7176 28.9066 20.1223 28.3222 20.359C27.7378 20.5957 27.0963 20.6538 26.4789 20.5259C25.8615 20.3979 25.2959 20.0897 24.8537 19.6403C24.4115 19.1908 24.1126 18.6203 23.9947 18.0009C23.8768 17.3815 23.9453 16.7411 24.1915 16.1606C24.4377 15.5801 24.8505 15.0857 25.3777 14.7399C25.905 14.3941 26.5229 14.2125 27.1534 14.218C27.998 14.2254 28.8052 14.5677 29.3975 15.1698C29.9899 15.7719 30.319 16.5844 30.3127 17.429Z" fill="#08F20C"/>
<path d="M30.3656 16.6145C30.3601 17.2446 30.1679 17.859 29.8133 18.3799C29.4586 18.9009 28.9576 19.305 28.3734 19.5412C27.7891 19.7775 27.148 19.8352 26.5309 19.7072C25.9139 19.5791 25.3487 19.271 24.9067 18.8218C24.4647 18.3726 24.1659 17.8024 24.0479 17.1834C23.9298 16.5644 23.998 15.9243 24.2436 15.3439C24.4893 14.7636 24.9015 14.2692 25.4282 13.9231C25.9548 13.577 26.5722 13.3947 27.2024 13.3994C27.6213 13.4026 28.0354 13.4882 28.4212 13.6515C28.8069 13.8149 29.1567 14.0526 29.4505 14.3512C29.7443 14.6498 29.9763 15.0034 30.1333 15.3917C30.2904 15.7801 30.3693 16.1956 30.3656 16.6145Z" fill="#08F30B"/>
<path d="M30.9596 16.4111C30.9529 17.0408 30.7598 17.6544 30.4046 18.1745C30.0495 18.6945 29.5482 19.0977 28.9641 19.3332C28.3801 19.5686 27.7393 19.6257 27.1228 19.4973C26.5063 19.3689 25.9416 19.0608 25.5001 18.6118C25.0585 18.1627 24.7599 17.5929 24.6419 16.9743C24.5239 16.3557 24.5918 15.716 24.8371 15.136C25.0823 14.556 25.4939 14.0616 26.0198 13.7152C26.5458 13.3689 27.1626 13.1862 27.7924 13.1901C28.2121 13.1927 28.6271 13.2781 29.0137 13.4416C29.4003 13.605 29.7509 13.8433 30.0452 14.1425C30.3394 14.4418 30.5717 14.7963 30.7286 15.1856C30.8856 15.5749 30.964 15.9913 30.9596 16.4111Z" fill="#08F40A"/>
<path d="M31.7482 16.4174C31.7431 17.0501 31.5504 17.6672 31.1945 18.1904C30.8386 18.7137 30.3356 19.1196 29.749 19.3569C29.1623 19.5943 28.5185 19.6523 27.8989 19.5236C27.2794 19.395 26.7118 19.0855 26.2681 18.6343C25.8245 18.1831 25.5246 17.6105 25.4064 16.9888C25.2882 16.3672 25.357 15.7244 25.6042 15.1419C25.8513 14.5593 26.2657 14.0632 26.7949 13.7162C27.324 13.3691 27.9442 13.1869 28.577 13.1924C29.4251 13.1997 30.2356 13.5436 30.8302 14.1483C31.4249 14.7531 31.7551 15.5692 31.7482 16.4174Z" fill="#08F50A"/>
<path d="M32.5379 16.4231C32.5327 17.0567 32.3398 17.6745 31.9835 18.1984C31.6272 18.7223 31.1235 19.1288 30.5362 19.3664C29.9489 19.604 29.3042 19.6621 28.6839 19.5333C28.0635 19.4046 27.4953 19.0947 27.0511 18.6429C26.6068 18.1912 26.3065 17.6178 26.1882 16.9954C26.0699 16.373 26.1388 15.7294 26.3862 15.1461C26.6337 14.5629 27.0485 14.0661 27.5784 13.7186C28.1082 13.3712 28.7291 13.1887 29.3627 13.1942C29.7833 13.1975 30.199 13.2838 30.5862 13.4479C30.9734 13.612 31.3245 13.8508 31.6194 14.1507C31.9143 14.4506 32.1471 14.8056 32.3048 15.1955C32.4624 15.5854 32.5416 16.0026 32.5379 16.4231Z" fill="#08F609"/>
<path d="M33.3257 16.4291C33.3209 17.0632 33.1283 17.6817 32.7722 18.2064C32.416 18.731 31.9123 19.1383 31.3248 19.3768C30.7372 19.6153 30.0921 19.6742 29.4711 19.5462C28.85 19.4181 28.2808 19.1088 27.8355 18.6574C27.3902 18.2059 27.0887 17.6326 26.9691 17.0099C26.8496 16.3871 26.9173 15.7429 27.1638 15.1586C27.4103 14.5744 27.8244 14.0763 28.3539 13.7273C28.8834 13.3784 29.5044 13.1942 30.1385 13.1981C30.5599 13.201 30.9767 13.2869 31.3649 13.4508C31.7532 13.6147 32.1053 13.8535 32.4013 14.1535C32.6972 14.4536 32.9312 14.809 33.0898 15.1994C33.2484 15.5898 33.3286 16.0077 33.3257 16.4291Z" fill="#09F708"/>
<path d="M34.1127 16.4349C34.1079 17.07 33.915 17.6894 33.5582 18.2148C33.2015 18.7402 32.6969 19.148 32.1084 19.3866C31.5198 19.6253 30.8738 19.6841 30.2518 19.5555C29.6299 19.427 29.06 19.1169 28.6143 18.6645C28.1686 18.2121 27.8671 17.6377 27.7478 17.0139C27.6285 16.3902 27.6969 15.745 27.9443 15.1601C28.1917 14.5752 28.607 14.0768 29.1376 13.7279C29.6683 13.379 30.2905 13.1953 30.9255 13.2C31.3472 13.2031 31.7642 13.2893 32.1526 13.4535C32.5409 13.6178 32.8932 13.8569 33.1891 14.1573C33.4851 14.4577 33.719 14.8135 33.8774 15.2043C34.0359 15.5951 34.1158 16.0132 34.1127 16.4349Z" fill="#09F807"/>
<path d="M34.9014 16.4432C34.8963 17.0791 34.7026 17.6992 34.345 18.2251C33.9874 18.751 33.4819 19.159 32.8923 19.3975C32.3028 19.636 31.6558 19.6944 31.0331 19.5651C30.4104 19.4359 29.8401 19.1249 29.3942 18.6715C28.9483 18.218 28.6468 17.6425 28.528 17.0178C28.4092 16.393 28.4784 15.7471 28.7267 15.1616C28.9751 14.5762 29.3915 14.0775 29.9233 13.7287C30.4551 13.38 31.0783 13.1968 31.7143 13.2022C32.5666 13.2096 33.3811 13.5552 33.9788 14.1629C34.5764 14.7706 34.9083 15.5908 34.9014 16.4432Z" fill="#09F906"/>
<path d="M35.6901 16.449C35.685 17.0859 35.491 17.7069 35.1328 18.2335C34.7745 18.7601 34.2681 19.1686 33.6777 19.4073C33.0872 19.646 32.4391 19.7042 31.8156 19.5745C31.192 19.4448 30.621 19.133 30.1747 18.6786C29.7284 18.2242 29.4269 17.6477 29.3084 17.0219C29.1899 16.3961 29.2597 15.7492 29.509 15.1631C29.7582 14.577 30.1758 14.078 30.7087 13.7293C31.2417 13.3806 31.8661 13.1978 32.503 13.2041C33.3558 13.212 34.1707 13.5582 34.7684 14.1667C35.366 14.7752 35.6976 15.5961 35.6901 16.449Z" fill="#09FA05"/>
<path d="M35.534 18.709C36.7927 17.4503 36.7927 15.4096 35.5341 14.151C34.2754 12.8923 32.2347 12.8923 30.976 14.151C29.7174 15.4096 29.7174 17.4503 30.976 18.709C32.2347 19.9676 34.2754 19.9676 35.534 18.709Z" fill="#09FB04"/>
<path d="M37.2664 16.461C37.2612 17.0993 37.0669 17.7217 36.708 18.2495C36.349 18.7774 35.8416 19.1869 35.2499 19.4263C34.6582 19.6658 34.0088 19.7243 33.3838 19.5946C32.7588 19.4649 32.1863 19.1528 31.7387 18.6977C31.2911 18.2426 30.9886 17.665 30.8693 17.0379C30.7501 16.4108 30.8194 15.7625 31.0687 15.1748C31.318 14.5872 31.7359 14.0867 32.2696 13.7366C32.8034 13.3865 33.429 13.2026 34.0673 13.2081C34.9228 13.2155 35.7403 13.5622 36.3402 14.1722C36.9401 14.7822 37.2733 15.6054 37.2664 16.461Z" fill="#09FC03"/>
<path d="M38.0551 16.4692C38.0496 17.1081 37.8547 17.731 37.4952 18.2592C37.1357 18.7874 36.6276 19.1971 36.0353 19.4366C35.4429 19.6761 34.7929 19.7346 34.1673 19.6047C33.5417 19.4747 32.9687 19.1623 32.5207 18.7067C32.0727 18.2512 31.7697 17.6731 31.6503 17.0454C31.5308 16.4177 31.6001 15.7687 31.8494 15.1805C32.0987 14.5922 32.5168 14.091 33.0509 13.7403C33.585 13.3897 34.2111 13.2052 34.85 13.2103C35.2746 13.2134 35.6943 13.3003 36.0853 13.4658C36.4762 13.6313 36.8307 13.8724 37.1284 14.1751C37.4261 14.4778 37.6612 14.8362 37.8202 15.2298C37.9792 15.6235 38.059 16.0446 38.0551 16.4692Z" fill="#09FD02"/>
</svg>

  );
};

export default LogoIcon;
