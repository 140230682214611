import Grid from "@mui/material/Grid";
import MyInput from "components/form/MyInput";

const JobPostStep3 = () => {
  return (
    <>
      <Grid container spacing={2}>
        <MyInput
          label="Job Description"
          name="description"
          multiline
          rows={12}
          lg={12}
          md={12}
          //
        />
      </Grid>
    </>
  );
};

export default JobPostStep3;
