import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import React, { useEffect, useMemo } from "react"
import MyInput from "components/form/MyInput"

export const JobPostStep1 = React.memo(
  ({
    setFieldValue,
    values,
    formOptions,
    touched,
    errors,
    isValid,
    dirty,
    isLoadingDraft,
  }) => {
    const categoryOptions = useMemo(
      () => [
        // { label: "", value: "default" },
        ...(formOptions?.categories?.map((category) => ({
          label: category.name,
          value: category.id,
        })) || []),
      ],
      [formOptions]
    )

    // console.log("values-1--->", values);

    const subCategoryOptions = useMemo(
      () => [
        // { label: "", value: "default" },
        ...(formOptions?.categories
          ?.find((cat) => cat.id === values?.job_category_id)
          ?.sub_categories?.map((category) => ({
            label: category.name,
            value: category.id,
          })) || []),
      ],
      [formOptions, values?.job_category_id]
    )

    // const subCategoryOptions = useMemo(() => {
    //   const selectedCategory = formOptions?.categories?.find(
    //     (cat) => cat.id === values?.job_category_id
    //   )

    //   console.log("Selected Category for Subcategories:", selectedCategory)

    //   return [
    //     { label: "Select Subcategory", value: "default" },
    //     ...(selectedCategory?.sub_categories?.map((subcategory) => ({
    //       label: subcategory.name,
    //       value: subcategory.id,
    //     })) || []),
    //   ]
    // }, [formOptions, values?.job_category_id])

    console.log("SubCategory Options:", subCategoryOptions)

    const jobLevelOptions = useMemo(
      () => [
        // { label: "", value: "default" },
        ...(formOptions?.jobLevels?.map((level) => ({
          label: level.name,
          value: level.id,
        })) || []),
      ],
      [formOptions]
    )

    const jobTypeOptions = useMemo(
      () => [
        // { label: "", value: "default" },
        ...(formOptions?.jobTypes?.map((type) => ({
          label: type.name,
          value: type.id,
        })) || []),
      ],
      [formOptions]
    )

    const payTypeOptions = useMemo(
      () => [
        // { label: "", value: "default" },
        ...(formOptions?.payTypes?.map((type) => ({
          label: type.name,
          value: type.id,
        })) || []),
      ],
      [formOptions]
    )

    const workplaceOptions = useMemo(
      () => [
        // { label: "", value: "default" },
        ...(formOptions?.workplaces?.map((type) => ({
          label: type.name,
          value: type.id,
        })) || []),
      ],
      [formOptions]
    )

    useEffect(() => {
      if (
        isLoadingDraft &&
        values.job_category_id &&
        subCategoryOptions.length > 1
      ) {
        // Only set the subcategory when loading draft data, not during manual selection
        if (!values.sub_category_id || values.sub_category_id === "default") {
          setFieldValue("sub_category_id", subCategoryOptions[1].value) // Set the first valid option
        }
      }
    }, [isLoadingDraft, values.job_category_id, subCategoryOptions])

    return (
      <Box>
        <Grid container spacing={2}>
          <MyInput
            label="Job Title"
            name="title"
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
            placeholder="Job Title"
          />

          <MyInput
            label="Job Category"
            type="select"
            name="job_category_id"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            onChange={(e) => {
              setFieldValue("job_category_id", e.target.value)
            }}
            options={categoryOptions}
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
          />
          <MyInput
            label="Sub Category"
            type="select"
            name="sub_category_id"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            options={subCategoryOptions}
            value={values.sub_category_id}
            onChange={(e) => {
              setFieldValue("sub_category_id", e.target.value)
            }}
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
          />
          <MyInput type="number" label="No. Of Vacancy" name="no_of_vacancy" />
          <MyInput label="Deadline" name="deadline" type="date" />
          <MyInput
            label="Job Level"
            type="select"
            name="job_level_id"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            options={jobLevelOptions}
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
          />
          <MyInput
            label="Job Type"
            type="select"
            name="job_type_id"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            options={jobTypeOptions}
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
          />
          <MyInput
            label="Pay Type"
            type="select"
            name="job_pay_type_id"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            options={payTypeOptions}
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
          />
          <MyInput
            label="Workplace Type"
            type="select"
            name="workplace_id"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            options={workplaceOptions}
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
          />
          <MyInput
            label="Salary Type"
            type="select"
            name="salary_type"
            labelKey="label"
            keyKey="value"
            valueKey="value"
            options={[
              { label: "Fixed", value: "Fixed" },
              { label: "Negotiable", value: "Negotiable" },
              { label: "Range", value: "Range" },
            ]}
            onChange={(e) => {
              let value_selected = e.target.value
              if (value_selected === "Fixed") {
                setFieldValue("salary_type", "Fixed")
                setFieldValue("is_negotiable", false)
              } else if (value_selected === "Negotiable") {
                setFieldValue("salary_type", "Negotiable")
                setFieldValue("salary_min", 100)
                setFieldValue("salary_max", 101)
                setFieldValue("is_negotiable", true)
              } else {
                setFieldValue("salary_type", "Range")
                setFieldValue("is_negotiable", false)
              }
            }}
            touched={touched}
            errors={errors}
            isValid={isValid}
            dirty={dirty}
          />
          {values?.salary_type === "Fixed" && (
            <MyInput
              label="Amount"
              name="salary_max"
              touched={touched}
              errors={errors}
              isValid={isValid}
              dirty={dirty}
            />
          )}
          {values?.salary_type === "Range" && (
            <>
              <MyInput
                label="Minimum Salary"
                name="salary_min"
                touched={touched}
                errors={errors}
                isValid={isValid}
                dirty={dirty}
              />
              <MyInput
                label="Maximum Salary"
                name="salary_max"
                touched={touched}
                errors={errors}
                isValid={isValid}
                dirty={dirty}
              />
            </>
          )}
        </Grid>
      </Box>
    )
  }
)
