import {
  CircularProgress,
  Container,
  Grid,
  Button,
  Step,
  StepLabel,
  Stepper,
  Box,
} from "@mui/material"
import MainCard from "components/cards/MainCard"
import { Formik, Form, useFormik, FormikProvider } from "formik"
import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { UrlBuilder } from "helpers/UrlBuilder"
import { JobPostStep1 } from "./JobPost/JobPostStep1"
import * as Yup from "yup"
import { JobPostStep2 } from "./JobPost/JobPostStep2"
import JobPostStep3 from "./JobPost/JobPostStep3"
import JobPostStep4 from "./JobPost/JobPostStep4"
import axios from "axios"
import Cookies from "js-cookie"

import { useLocation, useNavigate } from "react-router-dom"
import Moment from "moment"
import { setMessage } from "store/reducers/toastSlice"
import JobPostStep5 from "./JobPost/JobPostStep5"
import { callApi } from "store/reducers/apiSlice"

function CreateJobForm() {
  const [loading, setLoading] = useState(true)
  const [isLoadingDraft, setIsLoadingDraft] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState({
    slug: "ppp",
    title: "",
    job_category_id: "",
    main_category_id: "",
    sub_category_id: "",
    no_of_vacancy: 2,
    job_type_id: "",
    deadline: new Date(),
    job_level_id: "",
    description: "",
    workplace_id: "",
    address: {
      address: "",
      area: "",
      divisionId: "",
      district_id: "",
      sub_district_id: "",
    },
    job_pay_type_id: "",
    is_negotiable: false,
    age_min: 10,
    age_max: 40,
    edu_qualification_id: "",
    edu_requirements: "",
    job_exp_min: 0,
    question: "",
    publish: true,
    salary_min: 100,
    salary_max: 1000,
    steps: [
      "Basic Information",
      "Requirements",
      "Description",
      "Additional Questions",
    ],
    activeStep: 0,
    salary_type: "Fixed",
  })
  const [formOptions, setFormOptions] = useState({
    jobTypes: [],
    payTypes: [],
    jobLevels: [],
    workplaces: [],
    categories: [],
    education_qualifications: [],
    districts: [],
  })
  const location = useLocation()
  //const draft = location.state?.draft
  const id = location.state?.id

  // console.log("-----Form Options:--->", formOptions)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchData = useCallback(() => {
    const apiUrls = [
      UrlBuilder.jobServiceApi("job/types"),
      UrlBuilder.jobServiceApi("job/pay_types"),
      UrlBuilder.jobServiceApi("job/levels"),
      UrlBuilder.jobServiceApi("job/workplaces"),
      UrlBuilder.jobServiceApi("job/categories"),
      UrlBuilder.jobServiceApi("job/education_qualifications"),
      UrlBuilder.coreServiceApi("core/location/districts"),
    ]

    Promise.all(
      apiUrls.map((url) =>
        fetch(url).then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch details`)
          }
          return response.json()
        })
      )
    )
      .then((results) => {
        setFormOptions({
          jobTypes: results[0].data,
          payTypes: results[1].data,
          jobLevels: results[2].data,
          workplaces: results[3].data,
          categories: results[4].data,
          education_qualifications: results[5].data,
          districts: results[6].data,
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])
  const getDraftPost = () => {
    const accessToken = Cookies.get("gorilla-jobs-access_token")
    setIsLoadingDraft(true)
    axios
      .get(UrlBuilder.jobServiceApi(`job/posts/${id}?filter=draft`), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const draftData = response.data?.data
        console.log("draftData", draftData)

        setInitialFormValues({
          slug: draftData?.slug || "ppp",
          title: draftData?.title || "",
          job_category_id: draftData?.job_category_id || "",
          // main_category_id: draftData?.main_category_id || "",
          sub_category_id: "",
          no_of_vacancy: draftData?.no_of_vacancy || 2,
          job_type_id: draftData?.job_type_id || "",
          deadline: draftData?.deadline
            ? Moment(draftData.deadline).toDate()
            : new Date(),
          job_level_id: draftData?.job_level_id || "",
          description: draftData?.description || "",
          workplace_id: draftData?.workplace_id || "",
          address: {
            address: draftData?.address?.address || "",
            area: draftData?.address?.area || "",
            divisionId: draftData?.address?.divisionId || "",
            district_id: draftData?.address?.district_id || "",
            sub_district_id: draftData?.address?.sub_district_id || "",
          },
          // address: {
          //   address: draftData?.address?.address || "",
          //   area: draftData?.address?.area || "",
          //   divisionId: inferredDivisionId, // Set inferred divisionId here
          //   district_id: draftData?.address?.district_id || "",
          //   sub_district_id: draftData?.address?.sub_district_id || "",
          // },
          job_pay_type_id: draftData?.job_pay_type_id || "",
          is_negotiable: draftData?.is_negotiable || false,
          age_min: draftData?.age_min || 10,
          age_max: draftData?.age_max || 40,
          edu_qualification_id: draftData?.edu_qualification_id || "",
          edu_requirements: draftData?.edu_requirements || "",
          job_exp_min: draftData?.job_exp_min || 0,
          question: draftData?.question || "",
          publish: draftData?.publish || true,
          salary_min: draftData?.salary_min || 100,
          salary_max: draftData?.salary_max || 1000,
          steps: [
            "Basic Information",
            "Requirements",
            "Description",
            "Location",
            "Additional Questions",
          ],
          activeStep: 0,
          salary_type: draftData?.salary_type || "Fixed",
        })
        console.log("Job Category", draftData?.job_category_id)
        console.log("divisionId", draftData?.divisionId)
        console.log("district_id", draftData?.district_id)
        console.log("sub_district_id", draftData?.sub_district_id)
        setLoading(false)
        setIsLoadingDraft(false)
      })
      .catch((error) => {
        console.error("Error fetching draft post:", error)
        dispatch(
          setMessage({
            type: "error",
            message:
              error?.response?.data?.message || "Failed to fetch draft post",
          })
        )
        setLoading(false)
        setIsLoadingDraft(false)
      })
  }

  useEffect(() => {
    fetchData()
    if (id) {
      getDraftPost()
    } else {
      setInitialFormValues({
        slug: "ppp",
        title: "",
        job_category_id: "",
        // main_category_id: "",
        sub_category_id: "",
        no_of_vacancy: 2,
        job_type_id: "",
        deadline: new Date(),
        job_level_id: "",
        description: "",
        workplace_id: "",
        address: {
          address: "",
          area: "",
          divisionId: "",
          district_id: "",
          sub_district_id: "",
        },
        job_pay_type_id: "",
        is_negotiable: false,
        age_min: 10,
        age_max: 40,
        edu_qualification_id: "",
        edu_requirements: "",
        job_exp_min: 0,
        question: "",
        publish: true,
        salary_min: 100,
        salary_max: 1000,
        steps: [
          "Basic Information",
          "Requirements",
          "Description",
          "Location",
          "Additional Questions",
        ],
        activeStep: 0,
        salary_type: "Fixed",
      })
    }
  }, [fetchData, id])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // const handleSubmit = async (values) => {
    console.log("------submit  button clicked--------")
    try {
      const accessToken = Cookies.get("gorilla-jobs-access_token")
      const data = {
        ...values,
        slug: "ppp",
        deadline: Moment(values.deadline).format("YYYY-MM-DD"),
        salary_min: parseInt(values.salary_min, 10),
        salary_max: parseInt(values.salary_max, 10),
        publish: true,
        status: "APPROVED",
        is_negotiable: true,
      }

      // await axios.post(UrlBuilder.jobServiceApi("job/posts"), data, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${accessToken}`,
      //   },
      // })
      let response
      if (id) {
        response = await axios.put(
          UrlBuilder.jobServiceApi(`job/posts/${id}`),
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
      } else {
        // POST request if id does not exist
        response = await axios.post(
          UrlBuilder.jobServiceApi(`job/posts`),
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
      }
      resetForm()
      dispatch(setMessage({ type: "success", message: "Job Post Successful" }))
      navigate("/jobs")
    } catch (error) {
      dispatch(
        setMessage({
          type: "error",
          message: error?.message || "Something Went Wrong",
        })
      )
      console.error("Error posting job:", error.message)
    } finally {
      setSubmitting(false)
    }
  }

  const handleDraftPost = async (values) => {
    console.log("======draft post button clicked=====")

    try {
      const accessToken = Cookies.get("gorilla-jobs-access_token")
      const data = {
        ...values,
        job_type_id: parseInt(values.job_type_id, 10),
        job_level_id: parseInt(values.job_level_id, 10),
        workplace_id: parseInt(values.workplace_id, 10),
        job_category_id: parseInt(values.job_category_id, 10),
        // main_category_id: parseInt(values.main_category_id, 10),
        sub_category_id: parseInt(values.sub_category_id, 10),
        job_pay_type_id: parseInt(values.job_pay_type_id, 10),
        edu_qualification_id: parseInt(values.edu_qualification_id, 10),
        slug: "ppp",
        deadline: Moment(values.deadline).format("YYYY-MM-DD"),
        publish: false,
        address: {
          ...values.address,
          divisionId: parseInt(values.address.divisionId, 10),
          district_id: parseInt(values.address.district_id, 10),
          sub_district_id: parseInt(values.address.sub_district_id, 10),
        },
        salary_min: parseInt(values.salary_min, 10),
        salary_max: parseInt(values.salary_max, 10),
        status: "APPROVED",
        published_at: null,
        is_negotiable: true,
      }

      console.log("Draft data to be sent:", data)

      let response

      if (id) {
        response = await axios.put(
          UrlBuilder.jobServiceApi(`job/posts/${id}`),
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
      } else {
        // POST request if id does not exist
        response = await axios.post(
          UrlBuilder.jobServiceApi(`job/posts`),
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
      }

      console.log("Response:", response)

      dispatch(setMessage({ type: "success", message: "Draft Saved" }))
    } catch (error) {
      console.error(
        "Error saving draft:",
        error.response ? error.response.data : error.message
      )

      dispatch(
        setMessage({
          type: "error",
          message: error?.response?.data?.message || "Something Went Wrong",
        })
      )
    }
  }

  const stepValidationSchemas = [
    // Step 1 Validation Schema
    Yup.object().shape({
      title: Yup.string()
        .matches(
          /^[A-Za-z0-9\s]+$/,
          "Title must only contain letters, numbers, and spaces"
        )
        .min(3, "Title must be at minimum 3 characters")
        .required("Title is required"),
      job_category_id: Yup.string().required("Job category is required"),
      sub_category_id: Yup.string().required("Sub category is required"),
      no_of_vacancy: Yup.number().required("Number of vacancy is required"),
      deadline: Yup.date().required("Deadline is required"),
      job_type_id: Yup.string().required("Job type is required"),
      job_level_id: Yup.string().required("Job level is required"),
      workplace_id: Yup.string().required("Workplace is required"),
      salary_type: Yup.string().required("Pay type is required"),
    }),
    // Step 2 Validation Schema
    Yup.object().shape({
      edu_qualification_id: Yup.string().required(
        "Education qualification is required"
      ),
      edu_requirements: Yup.string()
        .matches(
          /^[A-Za-z0-9\s]+$/,
          "Education qualification must only contain letters, numbers, and spaces"
        )
        .required("Education qualification is required"),
    }),
    // Step 3 Validation Schema
    Yup.object().shape({
      description: Yup.string()
        .matches(
          /^[A-Za-z0-9\s]+$/,
          "Description must only contain letters, numbers, and spaces"
        )
        .required("Description is required"),
    }),
    // Step 4 Validation Schema
    Yup.object().shape({
      address: Yup.object().shape({
        address: Yup.string().required("Address is required"),
        divisionId: Yup.string().required("Division is required"),
        district_id: Yup.string().required("District is required"),
        sub_district_id: Yup.string().required("Sub-district is required"),
      }),
    }),
    // Step 5 Validation Schema
    Yup.object().shape({
      question: Yup.string()
        .matches(
          /^[A-Za-z0-9\s]+$/,
          "Question must only contain letters, numbers, and spaces"
        )
        .required("Question is required"),
    }),
  ]

  const validateStep = (step, values) => {
    const schema = stepValidationSchemas[step]
    try {
      schema.validateSync(values, { abortEarly: false })
      return {}
    } catch (error) {
      return error.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message
        return acc
      }, {})
    }
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialFormValues,
    validate: (values) => {
      const step = values.activeStep
      return validateStep(step, values)
    },
    onSubmit: handleSubmit,
  })

  const handleNext = () => {
    const errors = validateStep(formik.values.activeStep, formik.values)
    formik.setTouched(errors, true)
    if (Object.keys(errors).length === 0) {
      formik.setFieldValue("activeStep", formik.values.activeStep + 1)
    }
  }
  const handlePrevious = () => {
    formik.setFieldValue("activeStep", formik.values.activeStep - 1)
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <JobPostStep1
            setFieldValue={formik?.setFieldValue}
            values={formik?.values}
            formOptions={formOptions}
            touched={formik?.touched}
            errors={formik?.errors}
            isValid={formik?.isValid}
            dirty={formik?.dirty}
            isLoadingDraft={isLoadingDraft}
          />
        )
      case 1:
        return (
          <JobPostStep2
            setFieldValue={formik?.setFieldValue}
            values={formik?.values}
            formOptions={formOptions}
            touched={formik?.touched}
            errors={formik?.errors}
            isValid={formik?.isValid}
            dirty={formik?.dirty}
          />
        )
      case 2:
        return (
          <JobPostStep3
            setFieldValue={formik?.setFieldValue}
            values={formik?.values}
            formOptions={formOptions}
            touched={formik?.touched}
            errors={formik?.errors}
            isValid={formik?.isValid}
            dirty={formik?.dirty}
          />
        )
      case 3:
        return (
          <JobPostStep4
            setFieldValue={formik?.setFieldValue}
            values={formik?.values}
            formOptions={formOptions}
            touched={formik?.touched}
            errors={formik?.errors}
            isValid={formik?.isValid}
            dirty={formik?.dirty}
          />
        )
      case 4:
        return (
          <JobPostStep5
            setFieldValue={formik?.setFieldValue}
            values={formik?.values}
            formOptions={formOptions}
            touched={formik?.touched}
            errors={formik?.errors}
            isValid={formik?.isValid}
            dirty={formik?.dirty}
          />
        )
      default:
        return null
    }
  }
  return (
    <Container>
      <MainCard title="Job Post Form">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <FormikProvider value={formik}>
              <Form>
                <Stepper
                  activeStep={formik?.values?.activeStep}
                  alternativeLabel
                  sx={{
                    m: 4,
                    mb: 6,
                  }}
                >
                  {formik?.values?.steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        optional={
                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                sm: "none",
                                md: "block",
                                lg: "block",
                                xl: "block",
                              },
                            }}
                          >
                            {label}
                          </Box>
                        }
                      ></StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {renderStepContent(formik?.values?.activeStep)}
              </Form>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                  mt: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => handleDraftPost(formik?.values)}
                >
                  Save Draft Post
                </Button>
                {formik?.values?.activeStep !== 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={handlePrevious}
                  >
                    Previous Step
                  </Button>
                )}
                {formik?.values?.activeStep !== 4 && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={
                      Object.keys(
                        validateStep(formik.values.activeStep, formik.values)
                      ).length > 0
                    }
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
                {formik?.values?.activeStep === 4 && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      Object.keys(
                        validateStep(formik.values.activeStep, formik.values)
                      ).length > 0
                    }
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </FormikProvider>
          </>
        )}
      </MainCard>
    </Container>
  )
}

export default CreateJobForm
